import React from "react";

interface ChromeProps {
  width?: number;
  height?: number;
  className?: string;
}

const Chrome = ({ width = 40, height = 40, className }: ChromeProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.967 0.00548794C19.967 0.00548794 31.7358 -0.520582 37.9753 11.2628H18.9656C18.9656 11.2628 15.3781 11.1476 12.3136 15.4828C11.4333 17.3024 10.487 19.1768 11.5489 22.8708C10.0192 20.2894 3.42783 8.85685 3.42783 8.85685C3.42783 8.85685 8.07705 0.471324 19.9669 0.00548794H19.967Z"
        fill="#EF3F36"
      />
      <path
        d="M37.3645 29.9191C37.3645 29.9191 31.9339 40.3378 18.575 39.8226C20.2256 36.9781 28.0825 23.4245 28.0825 23.4245C28.0825 23.4245 29.9808 20.3882 27.7414 15.5763C26.6024 13.9047 25.4414 12.1563 21.7002 11.2245C24.7099 11.1973 37.9424 11.2245 37.9424 11.2245C37.9424 11.2245 42.8995 19.4292 37.3645 29.9191Z"
        fill="#FCD900"
      />
      <path
        d="M2.65202 30.0011C2.65202 30.0011 -3.69189 20.1085 3.43874 8.84033C5.08389 11.6848 12.9408 25.2385 12.9408 25.2385C12.9408 25.2385 14.6355 28.3954 19.9339 28.8665C21.9533 28.7186 24.055 28.5926 26.7401 25.8358C25.2598 28.4447 18.6187 39.8444 18.6187 39.8444C18.6187 39.8444 9.00124 40.0198 2.65186 30.0011H2.65202Z"
        fill="#61BC5B"
      />
      <path
        d="M18.5695 39.9212L21.2434 28.8065C21.2434 28.8065 24.1815 28.5761 26.6465 25.8853C25.1169 28.5654 18.5695 39.9212 18.5695 39.9212Z"
        fill="#5AB055"
      />
      <path
        d="M11.0097 20.0865C11.0097 15.1869 14.9987 11.2134 19.9175 11.2134C24.8362 11.2134 28.8253 15.1869 28.8253 20.0865C28.8253 24.9863 24.8362 28.9597 19.9175 28.9597C14.9987 28.9542 11.0097 24.9863 11.0097 20.0865Z"
        fill="white"
      />
      <path
        d="M12.5006 20.0866C12.5006 16.0091 15.8184 12.6987 19.9175 12.6987C24.0109 12.6987 27.3342 16.0036 27.3342 20.0866C27.3342 24.1643 24.0165 27.4746 19.9175 27.4746C15.8239 27.4746 12.5006 24.1643 12.5006 20.0866Z"
        fill="url(#paint0_linear_148_1365)"
      />
      <path
        d="M37.9367 11.23L26.927 14.4471C26.927 14.4471 25.2655 12.0191 21.6947 11.23C24.7924 11.2135 37.9367 11.23 37.9367 11.23Z"
        fill="#EACA05"
      />
      <path
        d="M11.3344 22.4653C9.78814 19.7962 3.42783 8.85693 3.42783 8.85693L11.5819 16.8915C11.5819 16.8915 10.7455 18.607 11.0592 21.0622L11.3342 22.4653H11.3344Z"
        fill="#DF3A32"
      />
      <defs>
        <linearGradient
          id="paint0_linear_148_1365"
          x1="19.9171"
          y1="12.803"
          x2="19.9171"
          y2="27.0299"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#86BBE5" />
          <stop offset="1" stopColor="#1072BA" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Chrome;
