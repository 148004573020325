import React from "react";

interface MoonProps {
  width: number;
  height: number;
  color?: string;
}

const Moon = ({ width, height, color }: MoonProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill={color}
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_228_518)">
        <path
          d="M7.5 0.347629C7.62202 0.495895 7.69677 0.677344 7.71459 0.868532C7.73242 1.05972 7.6925 1.25186 7.6 1.42013C6.87732 2.74697 6.49992 4.23425 6.5025 5.74513C6.5025 10.7714 10.6 14.8414 15.65 14.8414C16.3088 14.8414 16.95 14.7726 17.5662 14.6414C17.7552 14.6005 17.9519 14.6161 18.132 14.6864C18.3121 14.7566 18.4675 14.8784 18.5788 15.0364C18.6962 15.2007 18.7561 15.3992 18.7491 15.6011C18.7421 15.803 18.6686 15.9969 18.54 16.1526C17.5596 17.3569 16.3228 18.3273 14.9198 18.9929C13.5168 19.6585 11.9829 20.0026 10.43 20.0001C4.6675 20.0001 0 15.3576 0 9.63763C0 5.33263 2.6425 1.64013 6.405 0.075129C6.59242 -0.0040812 6.80049 -0.0203387 6.99794 0.0287986C7.19539 0.0779359 7.37156 0.189815 7.5 0.347629Z"
          fill="#FFB800"
        />
      </g>
      <defs>
        <clipPath id="clip0_228_518">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Moon;
