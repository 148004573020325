import React from "react";

interface DiscordProps {
  width?: number;
  height?: number;
  color?: string;
}

const Discord = ({
  width = 24,
  height = 24,
  color = "#5865F2",
}: DiscordProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.82 4.26001C14.6214 4.6157 14.4444 4.98303 14.29 5.36001C12.7728 5.12006 11.2272 5.12006 9.71 5.36001C9.55559 4.98303 9.3786 4.6157 9.18 4.26001C7.75079 4.50421 6.36141 4.94155 5.05 5.56001C2.70494 8.9443 1.64144 13.053 2.05 17.15C3.57824 18.2989 5.29346 19.1751 7.12 19.74C7.53591 19.1906 7.9071 18.6087 8.23 18C7.63408 17.7803 7.0613 17.5023 6.52 17.17C6.66846 17.0712 6.8089 16.9609 6.94 16.84C8.51846 17.6003 10.248 17.9952 12 17.9952C13.752 17.9952 15.4815 17.6003 17.06 16.84C17.2 16.96 17.34 17.07 17.48 17.17C16.9358 17.4997 16.3636 17.7807 15.77 18.01C16.0795 18.6325 16.4408 19.228 16.85 19.79C18.6743 19.227 20.3865 18.3506 21.91 17.2C22.3284 13.1022 21.264 8.99019 18.91 5.61001C17.6133 4.97875 16.2377 4.52468 14.82 4.26001ZM8.68 14.81C8.17958 14.7741 7.71254 14.5457 7.37692 14.1728C7.0413 13.7999 6.8632 13.3114 6.88 12.81C6.86066 12.3079 7.03789 11.8181 7.37401 11.4446C7.71014 11.0711 8.17866 10.8435 8.68 10.81C9.18134 10.8435 9.64986 11.0711 9.98599 11.4446C10.3221 11.8181 10.4993 12.3079 10.48 12.81C10.4993 13.3121 10.3221 13.8019 9.98599 14.1754C9.64986 14.5489 9.18134 14.7765 8.68 14.81ZM15.32 14.81C14.8196 14.7741 14.3525 14.5457 14.0169 14.1728C13.6813 13.7999 13.5032 13.3114 13.52 12.81C13.5007 12.3079 13.6779 11.8181 14.014 11.4446C14.3501 11.0711 14.8187 10.8435 15.32 10.81C15.8223 10.841 16.2923 11.0679 16.6289 11.442C16.9656 11.816 17.1419 12.3073 17.12 12.81C17.1419 13.3128 16.9656 13.804 16.6289 14.1781C16.2923 14.5521 15.8223 14.779 15.32 14.81Z"
        fill={color}
      />
    </svg>
  );
};

export default Discord;
