import React from "react";
interface CheckProps {
  width?: number;
  height?: number;
  className?: string;
}

const Check = ({ width = 16, height = 16, className }: CheckProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_148_1591)">
        <path
          d="M13.851 0.72696C13.7973 0.749817 13.6937 0.800103 13.6208 0.841246C13.5479 0.88696 11.6946 3.05382 9.50376 5.65953L5.52486 10.4001L3.99777 8.58525C2.95412 7.34639 2.42079 6.74296 2.30184 6.66982C2.04093 6.51439 1.62271 6.4961 1.32342 6.63325C1.12774 6.7201 1.02798 6.8161 0.62894 7.28696C0.0764225 7.94982 -0.0118269 8.1281 -0.0156639 8.6401C-0.0233377 9.29839 -0.165304 9.09268 2.58577 12.3841C4.25868 14.3864 5.09513 15.351 5.19872 15.4104C5.39441 15.5201 5.65532 15.5201 5.851 15.4104C5.95844 15.351 7.51623 13.5224 10.9196 9.45839C16.4064 2.9121 15.9843 3.46525 15.9767 2.78867C15.9728 2.27667 15.8846 2.09839 15.3321 1.43553C14.9407 0.969246 14.8333 0.868674 14.6452 0.786388C14.4189 0.685818 14.0467 0.658389 13.851 0.72696Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_148_1591">
          <rect width="16" height="16" fill="#000" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Check;
