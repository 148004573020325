import React from "react";

interface FoldersProps {
  width?: number;
  height?: number;
  className?: string;
}

const Folders = ({ width = 268, height = 217, className }: FoldersProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 268 217"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_852_163)">
        <path
          d="M137.263 211C206.154 211 262 208.997 262 206.527C262 204.056 206.154 202.054 137.263 202.054C68.373 202.054 12.5264 204.056 12.5264 206.527C12.5264 208.997 68.373 211 137.263 211Z"
          fill="#15C39A"
        />
        <path
          opacity="0.8"
          d="M137.263 211C206.154 211 262 208.997 262 206.527C262 204.056 206.154 202.054 137.263 202.054C68.373 202.054 12.5264 204.056 12.5264 206.527C12.5264 208.997 68.373 211 137.263 211Z"
          fill="white"
        />
        <path
          d="M197.215 38.3833C196.969 38.3833 196.734 38.4773 196.561 38.6446C196.387 38.812 196.29 39.0389 196.29 39.2756V43.8499H179.391V39.2756C179.391 39.0389 179.293 38.812 179.12 38.6446C178.946 38.4773 178.711 38.3833 178.466 38.3833C178.221 38.3833 177.985 38.4773 177.812 38.6446C177.638 38.812 177.541 39.0389 177.541 39.2756V154.496C177.541 154.733 177.638 154.96 177.812 155.127C177.985 155.294 178.221 155.388 178.466 155.388C178.711 155.388 178.946 155.294 179.12 155.127C179.293 154.96 179.391 154.733 179.391 154.496V128.722H196.29V154.496C196.29 154.733 196.387 154.96 196.561 155.127C196.734 155.294 196.969 155.388 197.215 155.388C197.46 155.388 197.695 155.294 197.869 155.127C198.042 154.96 198.139 154.733 198.139 154.496V39.2577C198.135 39.0242 198.035 38.8018 197.862 38.6383C197.689 38.4748 197.457 38.3833 197.215 38.3833V38.3833ZM196.29 126.955H179.391V121.173H196.29V126.955ZM196.29 119.383H179.391V113.607H196.29V119.383ZM196.29 111.816H179.391V106.058H196.29V111.816ZM196.29 104.256H179.391V98.5216H196.29V104.256ZM196.29 96.7014H179.391V91.0326H196.29V96.7014ZM196.29 89.1707H179.391V83.4662H196.29V89.1707ZM196.29 81.6758H179.391V75.9177H196.29V81.6758ZM196.29 74.1094H179.391V68.3513H196.29V74.1094ZM196.29 66.5609H179.391V60.785H196.29V66.5609ZM196.29 58.9945H179.391V53.2067H196.29V58.9945ZM196.29 51.4222H179.391V45.6344H196.29V51.4222Z"
          fill="white"
        />
        <path
          d="M37.1368 131.274C37.1368 131.274 50.978 112.994 44.2269 108.67C37.2046 104.202 33.9062 117.735 32.4203 124.379C29.2513 138.56 30.1453 141.57 32.2723 138.453L37.1368 131.274Z"
          fill="#15C39A"
        />
        <path
          opacity="0.5"
          d="M37.1368 131.274C37.1368 131.274 50.978 112.994 44.2269 108.67C37.2046 104.202 33.9062 117.735 32.4203 124.379C29.2513 138.56 30.1453 141.57 32.2723 138.453L37.1368 131.274Z"
          fill="white"
        />
        <path
          d="M42.3285 142.516C42.3285 142.516 61.5705 129.56 56.6321 123.386C51.4964 116.956 43.8205 128.74 40.1768 134.545C32.4023 146.965 32.242 150.094 35.3 147.81L42.3285 142.516Z"
          fill="#15C39A"
        />
        <path
          opacity="0.5"
          d="M42.3285 142.516C42.3285 142.516 61.5705 129.56 56.6321 123.386C51.4964 116.956 43.8205 128.74 40.1768 134.545C32.4023 146.965 32.242 150.094 35.3 147.81L42.3285 142.516Z"
          fill="white"
        />
        <path
          d="M42.7475 157.542C42.7475 157.542 59.3939 150.326 56.5517 144.913C53.5923 139.28 45.9535 147.203 42.2974 151.141C34.4859 159.552 33.8941 161.92 36.576 160.605L42.7475 157.542Z"
          fill="#15C39A"
        />
        <path
          opacity="0.5"
          d="M42.7475 157.542C42.7475 157.542 59.3939 150.326 56.5517 144.913C53.5923 139.28 45.9535 147.203 42.2974 151.141C34.4859 159.552 33.8941 161.92 36.576 160.605L42.7475 157.542Z"
          fill="white"
        />
        <path
          d="M43.7336 172.633C43.7336 172.633 60.3799 165.417 57.5439 160.004C54.5784 154.377 46.9457 162.294 43.2835 166.238C35.4782 174.649 34.8801 177.017 37.5682 175.696L43.7336 172.633Z"
          fill="#15C39A"
        />
        <path
          opacity="0.5"
          d="M43.7336 172.633C43.7336 172.633 60.3799 165.417 57.5439 160.004C54.5784 154.377 46.9457 162.294 43.2835 166.238C35.4782 174.649 34.8801 177.017 37.5682 175.696L43.7336 172.633Z"
          fill="white"
        />
        <path
          d="M24.2091 138.911C24.2091 138.911 5.91041 124.736 1.15694 131.042C-3.77533 137.585 10.072 141.41 16.8847 143.147C31.4226 146.853 34.5792 146.121 31.4472 143.932L24.2091 138.911Z"
          fill="#15C39A"
        />
        <path
          opacity="0.5"
          d="M24.2091 138.911C24.2091 138.911 5.91041 124.736 1.15694 131.042C-3.77533 137.585 10.072 141.41 16.8847 143.147C31.4226 146.853 34.5792 146.121 31.4472 143.932L24.2091 138.911Z"
          fill="white"
        />
        <path
          d="M24.7509 150.755C24.7509 150.755 3.89973 140.369 0.539625 147.465C-2.95612 154.847 11.4091 155.918 18.4191 156.31C33.4317 157.155 36.3787 155.835 32.8583 154.288L24.7509 150.755Z"
          fill="#15C39A"
        />
        <path
          opacity="0.5"
          d="M24.7509 150.755C24.7509 150.755 3.89973 140.369 0.539625 147.465C-2.95612 154.847 11.4091 155.918 18.4191 156.31C33.4317 157.155 36.3787 155.835 32.8583 154.288L24.7509 150.755Z"
          fill="white"
        />
        <path
          d="M27.2732 164.031C27.2732 164.031 9.39377 155.703 6.72418 161.789C3.94362 168.112 16.114 168.695 22.0759 168.867C34.7888 169.248 37.2487 168.058 34.2339 166.869L27.2732 164.031Z"
          fill="#15C39A"
        />
        <path
          opacity="0.5"
          d="M27.2732 164.031C27.2732 164.031 9.39377 155.703 6.72418 161.789C3.94362 168.112 16.114 168.695 22.0759 168.867C34.7888 169.248 37.2487 168.058 34.2339 166.869L27.2732 164.031Z"
          fill="white"
        />
        <path
          d="M28.1473 174.756C28.1473 174.756 11.279 164.644 7.95587 170.42C4.49712 176.422 16.538 178.248 22.4444 179.033C35.0463 180.711 37.6173 179.789 34.7751 178.26L28.1473 174.756Z"
          fill="#15C39A"
        />
        <path
          opacity="0.5"
          d="M28.1473 174.756C28.1473 174.756 11.279 164.644 7.95587 170.42C4.49712 176.422 16.538 178.248 22.4444 179.033C35.0463 180.711 37.6173 179.789 34.7751 178.26L28.1473 174.756Z"
          fill="white"
        />
        <path
          d="M26.5506 120.953C26.5506 120.953 23.3816 94.4054 12.9868 97.1179C2.19134 99.9493 11.2297 113.381 15.8167 119.876C25.5641 133.701 29.1523 135.223 28.3817 130.905L26.5506 120.953Z"
          fill="#15C39A"
        />
        <path
          opacity="0.5"
          d="M26.5506 120.953C26.5506 120.953 23.3816 94.4054 12.9868 97.1179C2.19134 99.9493 11.2297 113.381 15.8167 119.876C25.5641 133.701 29.1523 135.223 28.3817 130.905L26.5506 120.953Z"
          fill="white"
        />
        <path
          d="M15.1143 106.79C15.1143 106.79 30.9345 135.39 33.3883 148.667C36.212 163.918 36.6683 180.955 34.72 194.303"
          stroke="#15C39A"
          strokeWidth="0.881763"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.8916 139.833L38.6414 116.932"
          stroke="#15C39A"
          strokeWidth="0.881763"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.3877 148.667L47.9749 130.97"
          stroke="#15C39A"
          strokeWidth="0.881763"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.4584 145.449L11.3359 136.889"
          stroke="#15C39A"
          strokeWidth="0.881763"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M35.2188 160.653L51.1007 148.911"
          stroke="#15C39A"
          strokeWidth="0.881763"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.3751 155.615L17.7842 152.283"
          stroke="#15C39A"
          strokeWidth="0.881763"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M35.1708 167.874L22.3223 165.911"
          stroke="#15C39A"
          strokeWidth="0.881763"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36.2549 175.809L44.8987 168.338"
          stroke="#15C39A"
          strokeWidth="0.881763"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36.0824 179.17L21.3164 175.589"
          stroke="#15C39A"
          strokeWidth="0.881763"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.1455 183.53H42.5996V187.016H41.0767L37.9262 206.033H29.8188L26.6683 187.016H25.1455V183.53Z"
          fill="#15C39A"
        />
        <path
          opacity="0.5"
          d="M25.1455 183.53H42.5996V187.016H41.0767L37.9262 206.033H29.8188L26.6683 187.016H25.1455V183.53Z"
          fill="white"
        />
        <path
          d="M53.8868 3.95396C53.13 3.30158 52.206 2.85658 51.2107 2.66516C50.2155 2.47373 49.1852 2.54288 48.227 2.8654C44.8361 3.95395 41.587 8.68293 42.407 12.6862C42.9803 15.4582 45.6438 19.5447 46.4514 21.1032C47.2591 22.6617 46.1246 25.2968 43.5599 30.7693C40.9951 36.2419 40.0025 41.3753 47.4379 45.8961C54.8733 50.4169 63.2889 43.2431 67.6047 38.0978C71.9204 32.9524 64.3679 21.1032 63.7205 16.0887C63.0731 11.0742 61.9634 1.46158 53.8868 3.95396Z"
          fill="#263238"
        />
        <path
          d="M71.3655 38.4426C69.5154 40.2286 67.28 41.599 64.8183 42.4565C62.3567 43.314 59.7295 43.6373 57.1236 43.4036L55.3418 50.5714L58.7327 66.9355L80.8724 55.8655L75.86 43.3738L71.3655 38.4426Z"
          fill="#15C39A"
          stroke="#263238"
          strokeWidth="0.7043"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.3"
          d="M71.3655 38.4426C69.5154 40.2286 67.28 41.599 64.8183 42.4565C62.3567 43.314 59.7295 43.6373 57.1236 43.4036L55.3418 50.5714L58.7327 66.9355L80.8724 55.8655L75.86 43.3738L71.3655 38.4426Z"
          fill="#263238"
        />
        <path
          d="M71.3658 38.4428L67.9749 16.7668L57.9254 18.7536L52.913 21.8706C52.913 21.8706 46.9326 26.3973 46.9326 26.8613C46.9326 27.3253 57.0499 43.6596 57.0499 43.6596C59.6822 43.8098 62.3177 43.4229 64.7844 42.524C67.2512 41.6251 69.4938 40.2345 71.3658 38.4428Z"
          fill="white"
        />
        <path
          d="M71.3658 38.4428L67.9749 16.7668L57.9254 18.7536L52.913 21.8706C52.913 21.8706 46.9326 26.3973 46.9326 26.8613C46.9326 27.3253 57.0499 43.6596 57.0499 43.6596C59.6822 43.8098 62.3177 43.4229 64.7844 42.524C67.2512 41.6251 69.4938 40.2345 71.3658 38.4428Z"
          stroke="#263238"
          strokeWidth="0.7043"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.5"
          d="M57.882 37.5147C57.882 37.5147 64.966 32.4467 64.3125 33.9457C63.6589 35.4447 58.7637 39.1862 57.4505 39.2993C56.1372 39.4123 57.882 37.5147 57.882 37.5147Z"
          fill="#15C39A"
        />
        <path
          d="M58.0977 27.8902L58.3751 28.1698L60.1014 29.9305L60.5453 36.6938L62.66 36.3726L60.9892 30.0376L63.6649 35.403L65.8906 33.2556L61.1063 29.068L60.9892 25.7428L58.5416 24.5591"
          fill="#263238"
        />
        <path
          d="M54.2078 19.8602C54.2078 19.8602 52.1055 20.7941 52.1055 22.6619C52.1055 24.5297 54.3805 28.9731 55.8047 29.907C57.2289 30.8409 59.6703 26.564 59.6703 26.564C59.6703 26.564 63.3695 28.2772 64.356 26.7187C65.3424 25.1602 62.0933 20.0148 59.9971 18.611C57.9009 17.2072 54.2078 19.8602 54.2078 19.8602Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.7043"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M52.4336 17.9862C53.0117 19.4111 53.4976 20.8693 53.8886 22.3523C54.2092 23.9108 58.5743 26.5638 59.0552 26.7184C59.5361 26.8731 59.8628 21.7277 59.8628 21.7277L58.5743 15.6484L52.4336 17.9862Z"
          fill="white"
        />
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path
            d="M58.7092 16.2969C58.4381 16.2236 58.162 16.1679 57.8831 16.1304L53.4255 17.6472L52.8398 19.1224C53.1358 19.9195 53.5119 20.9902 53.74 21.8587C54.4708 22.4271 55.3537 22.784 56.2863 22.8878C57.6241 22.977 58.8017 21.9776 59.634 20.7523L58.7092 16.2969Z"
            fill="#15C39A"
          />
        </g>
        <path
          d="M52.4336 17.9862C53.0117 19.4111 53.4976 20.8693 53.8886 22.3523C54.2092 23.9108 58.5743 26.5638 59.0552 26.7184C59.5361 26.8731 59.8628 21.7277 59.8628 21.7277L58.5743 15.6484L52.4336 17.9862Z"
          stroke="#263238"
          strokeWidth="0.7043"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M49.523 15.3332C49.523 15.3332 47.7412 13.9353 47.7412 15.3332C47.7412 16.7311 49.3627 17.5162 50.6512 17.5162"
          fill="white"
        />
        <path
          d="M49.523 15.3332C49.523 15.3332 47.7412 13.9353 47.7412 15.3332C47.7412 16.7311 49.3627 17.5162 50.6512 17.5162"
          stroke="#263238"
          strokeWidth="0.7043"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M59.0537 10.8124C59.0537 10.8124 60.1881 9.09924 60.5087 10.973C60.6564 12.1928 60.3096 13.4205 59.5408 14.3993L59.0537 10.8124Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.7043"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g opacity="0.5">
          <path
            d="M49.523 15.3332C49.523 15.3332 47.7412 13.9353 47.7412 15.3332C47.7412 16.7311 49.3627 17.5162 50.6512 17.5162"
            fill="#15C39A"
          />
          <path
            d="M49.523 15.3332C49.523 15.3332 47.7412 13.9353 47.7412 15.3332C47.7412 16.7311 49.3627 17.5162 50.6512 17.5162"
            stroke="#263238"
            strokeWidth="0.7043"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <path
          opacity="0.5"
          d="M59.0537 10.8124C59.0537 10.8124 60.1881 9.09924 60.5087 10.973C60.6564 12.1928 60.3096 13.4205 59.5408 14.3993L59.0537 10.8124Z"
          fill="#15C39A"
          stroke="#263238"
          strokeWidth="0.7043"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M47.5186 10.8243C47.5186 10.8243 48.9058 16.3979 51.8836 19.152C54.8615 21.9061 58.0489 21.9299 59.1525 17.0225C60.0588 12.9776 57.8085 7.58238 57.8085 7.58238C57.8085 7.58238 50.5457 1.81243 47.5186 10.8243Z"
          fill="white"
        />
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path
            d="M52.6914 5.76848L53.8197 8.50474C53.8197 8.50474 55.5953 8.12404 56.5941 10.1941C57.5928 12.2641 58.5978 15.2978 58.0244 17.6474C57.4757 19.884 56.1748 20.6216 54.1711 20.6752C56.3536 21.5734 58.3327 20.6752 59.1527 17.011C60.059 12.966 57.8086 7.57084 57.8086 7.57084C57.8086 7.57084 55.3487 5.62572 52.6914 5.76848Z"
            fill="#15C39A"
          />
        </g>
        <path
          d="M47.5186 10.8243C47.5186 10.8243 48.9058 16.3979 51.8836 19.152C54.8615 21.9061 58.0489 21.9299 59.1525 17.0225C60.0588 12.9776 57.8085 7.58238 57.8085 7.58238C57.8085 7.58238 50.5457 1.81243 47.5186 10.8243Z"
          stroke="#263238"
          strokeWidth="0.7043"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M53.0294 13.7687C53.202 14.0839 53.1712 14.423 52.9616 14.5301C52.752 14.6371 52.4437 14.4646 52.2772 14.1494C52.1108 13.8341 52.1354 13.501 52.345 13.3939C52.5547 13.2868 52.8629 13.4593 53.0294 13.7687Z"
          fill="#263238"
        />
        <path
          d="M56.2481 12.8051C56.3715 13.1382 56.2913 13.4713 56.0693 13.5427C55.8474 13.6141 55.5638 13.4119 55.4528 13.0788C55.3418 12.7456 55.4097 12.4125 55.6316 12.3411C55.8536 12.2698 56.1248 12.4839 56.2481 12.8051Z"
          fill="#263238"
        />
        <path
          d="M56.4704 11.0207C56.2185 10.8574 55.9182 10.7783 55.6153 10.7955C55.3124 10.8127 55.0237 10.9252 54.7934 11.1159C53.9056 11.7821 55.5826 15.1191 55.5826 15.1191C55.5826 15.1191 55.6134 16.172 54.2139 15.6842"
          stroke="#263238"
          strokeWidth="0.35215"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M56.47 16.7668C56.374 16.9859 56.2265 17.1804 56.0392 17.3351C55.8519 17.4897 55.63 17.6002 55.3908 17.6579C55.1516 17.7156 54.9019 17.7189 54.6612 17.6675C54.4205 17.6161 54.1955 17.5114 54.0039 17.3617"
          stroke="#263238"
          strokeWidth="0.35215"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M52.7161 11.9068C52.7161 11.9068 51.1378 10.4732 50.4473 13.2392"
          stroke="#263238"
          strokeWidth="0.35215"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M57.901 5.81596C56.1686 4.36455 54.769 4.3824 54.1587 4.57275C53.5873 4.22878 52.9376 4.02459 52.2659 3.97791C47.42 3.35332 46.7726 6.9521 46.7726 10.3724C46.7726 13.7928 43.6899 17.8555 45.8046 18.3254C46.3834 18.4594 46.9925 18.3921 47.5249 18.1353C48.0572 17.8785 48.4785 17.4487 48.7147 16.9216C48.7147 16.9216 50.9774 13.3526 50.9774 10.8364C50.9774 8.32024 53.7949 8.55817 53.7949 8.55817C53.9166 8.38491 54.0756 8.23908 54.2611 8.13067C54.4467 8.02227 54.6543 7.95385 54.8698 7.93012C55.0853 7.90639 55.3036 7.92791 55.5097 7.99319C55.7158 8.05847 55.9048 8.16598 56.0638 8.30834C57.3461 9.36715 57.4941 10.6104 58.5299 10.9316C59.5657 11.2528 61.2981 9.64673 61.2981 9.64673C60.3112 8.25612 59.1723 6.97183 57.901 5.81596V5.81596Z"
          fill="#263238"
        />
        <path
          d="M53.93 6.74967C53.93 6.74967 51.9818 5.23878 50.2308 6.93407C48.4799 8.62936 48.671 11.8356 48.0853 13.9115C47.4996 15.9875 45.9336 16.3623 45.9336 16.3623"
          stroke="white"
          strokeWidth="0.35215"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M52.5664 6.18449C52.5664 6.18449 50.464 4.17393 47.8191 6.71985C45.803 8.67092 46.7032 9.94983 46.3086 13.156"
          stroke="white"
          strokeWidth="0.35215"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M54.9111 5.80396C54.9111 5.80396 58.6103 4.10866 60.1887 8.44504"
          stroke="white"
          strokeWidth="0.35215"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M46.9328 26.873L41.3408 48.5847L57.7406 53.0698L58.5483 50.2622L47.7774 45.4677L51.4335 32.9761C51.4335 32.9761 50.9526 30.6384 48.3508 27.9913"
          fill="white"
        />
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path
            d="M58.5726 50.2802L47.8018 45.4858L51.4578 32.9941C51.1715 31.9572 50.7067 30.9739 50.083 30.0854L49.7315 29.8296V33.2797C49.7315 35.4865 46.8708 42.5235 45.8659 44.1771C44.8609 45.8308 43.2949 47.9008 43.2949 47.9008L46.4392 46.5208L58.3568 51.1249L58.5726 50.2802Z"
            fill="#15C39A"
          />
        </g>
        <path
          d="M46.9328 26.873L41.3408 48.5847L57.7406 53.0698L58.5483 50.2622L47.7774 45.4677L51.4335 32.9761C51.4335 32.9761 50.9526 30.6384 48.3508 27.9913"
          stroke="#263238"
          strokeWidth="0.7043"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M93.0424 33.3926L80.6254 30.8764L67.9434 16.7371L69.8176 28.1104L77.5983 35.2604L93.2335 35.9504L93.0424 33.3926Z"
          fill="white"
        />
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path
            d="M67.9434 16.7371L69.4539 25.8143L70.4157 28.6517L70.539 28.7588C70.9027 28.3959 72.9558 26.3378 73.4798 26.3378C74.0039 26.3378 79.9164 31.3047 79.0595 31.6913L78.1963 32.1018L92.9253 34.4454L93.0301 33.3569L80.6378 30.8467L67.9434 16.7371Z"
            fill="#15C39A"
          />
        </g>
        <path
          d="M93.0424 33.3926L80.6254 30.8764L67.9434 16.7371L69.8176 28.1104L77.5983 35.2604L93.2335 35.9504L93.0424 33.3926Z"
          stroke="#263238"
          strokeWidth="0.7043"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M208.958 153.782L50.836 131.428C50.3333 131.356 49.8808 131.095 49.5779 130.701C49.2751 130.307 49.1466 129.813 49.2207 129.328L62.3899 42.6542C62.4261 42.4143 62.511 42.1836 62.6397 41.9754C62.7684 41.7672 62.9384 41.5855 63.14 41.4408C63.3416 41.296 63.5707 41.191 63.8144 41.1318C64.0581 41.0726 64.3115 41.0603 64.5601 41.0957L67.5564 41.5181C67.9359 41.573 68.3238 41.5161 68.6693 41.3548C69.0147 41.1934 69.3016 40.9352 69.4923 40.6139L73.469 33.9814C73.5897 33.7732 73.7534 33.591 73.9499 33.4461C74.8808 32.7966 76.0377 32.5234 77.1743 32.6847L117.033 38.3238C117.927 38.4476 118.76 38.8339 119.419 39.4302C119.595 39.6 119.733 39.8023 119.826 40.025L123.371 48.4955C123.496 48.7951 123.699 49.0579 123.962 49.2573C124.225 49.4568 124.538 49.5859 124.869 49.6317L222.682 63.4677C223.185 63.5383 223.638 63.7985 223.942 64.191C224.246 64.5836 224.376 65.0765 224.304 65.5615L211.135 152.23C211.059 152.714 210.787 153.149 210.379 153.44C209.971 153.731 209.46 153.854 208.958 153.782V153.782Z"
          fill="#263238"
          stroke="#263238"
          strokeWidth="0.7043"
          strokeMiterlimit="10"
        />
        <path
          d="M210.641 153.36H53.9122C53.4572 153.36 53.0066 153.273 52.5862 153.105C52.1658 152.937 51.7839 152.691 51.4621 152.381C51.1404 152.07 50.8851 151.702 50.711 151.296C50.5369 150.89 50.4473 150.456 50.4473 150.017V65.4067C50.4473 64.5201 50.8123 63.6698 51.4621 63.0428C52.1119 62.4159 52.9932 62.0637 53.9122 62.0637H54.3376C55.0313 62.0623 55.7088 61.8612 56.2832 61.4861C56.8577 61.1109 57.303 60.5788 57.5621 59.9579L59.948 54.1701C60.2147 53.5021 60.7023 52.9376 61.3352 52.5641C62.0152 52.1768 62.7898 51.9714 63.5794 51.9692H103.864C104.405 51.9687 104.942 52.0634 105.448 52.2488C106.151 52.5222 106.738 53.0169 107.113 53.6526L111.213 60.3922C111.518 60.8956 111.955 61.3131 112.48 61.6032C113.004 61.8933 113.598 62.0459 114.203 62.0458H210.641C211.56 62.0458 212.441 62.398 213.091 63.025C213.741 63.6519 214.106 64.5022 214.106 65.3888V150.017C214.106 150.456 214.016 150.89 213.842 151.296C213.668 151.702 213.413 152.07 213.091 152.381C212.769 152.691 212.387 152.937 211.967 153.105C211.547 153.273 211.096 153.36 210.641 153.36V153.36Z"
          fill="#15C39A"
        />
        <path
          opacity="0.1"
          d="M210.64 153.36H53.9112C53.4562 153.36 53.0056 153.273 52.5852 153.105C52.1649 152.937 51.7829 152.691 51.4611 152.381C51.1394 152.07 50.8842 151.702 50.71 151.296C50.5359 150.89 50.4463 150.456 50.4463 150.017V65.4067C50.4463 64.5201 50.8113 63.6698 51.4611 63.0428C52.1109 62.4159 52.9922 62.0637 53.9112 62.0637H54.3366C55.0303 62.0623 55.7078 61.8612 56.2823 61.4861C56.8567 61.1109 57.302 60.5788 57.5611 59.9579L59.9471 54.1701C60.2138 53.5021 60.7013 52.9376 61.3343 52.5641C62.0142 52.1768 62.7889 51.9714 63.5784 51.9692H103.863C104.404 51.9687 104.941 52.0634 105.447 52.2488C106.15 52.5222 106.737 53.0169 107.112 53.6526L111.212 60.3922C111.517 60.8956 111.954 61.3131 112.479 61.6032C113.003 61.8933 113.597 62.0459 114.202 62.0458H210.64C211.559 62.0458 212.44 62.398 213.09 63.025C213.74 63.6519 214.105 64.5022 214.105 65.3888V150.017C214.105 150.456 214.015 150.89 213.841 151.296C213.667 151.702 213.412 152.07 213.09 152.381C212.768 152.691 212.387 152.937 211.966 153.105C211.546 153.273 211.095 153.36 210.64 153.36V153.36Z"
          fill="#263238"
        />
        <path
          d="M210.641 153.36H53.9122C53.4572 153.36 53.0066 153.273 52.5862 153.105C52.1658 152.937 51.7839 152.691 51.4621 152.381C51.1404 152.07 50.8851 151.702 50.711 151.296C50.5369 150.89 50.4473 150.456 50.4473 150.017V65.4067C50.4473 64.5201 50.8123 63.6698 51.4621 63.0428C52.1119 62.4159 52.9932 62.0637 53.9122 62.0637H54.3376C55.0313 62.0623 55.7088 61.8612 56.2832 61.4861C56.8577 61.1109 57.303 60.5788 57.5621 59.9579L59.948 54.1701C60.2147 53.5021 60.7023 52.9376 61.3352 52.5641C62.0152 52.1768 62.7898 51.9714 63.5794 51.9692H103.864C104.405 51.9687 104.942 52.0634 105.448 52.2488C106.151 52.5222 106.738 53.0169 107.113 53.6526L111.213 60.3922C111.518 60.8956 111.955 61.3131 112.48 61.6032C113.004 61.8933 113.598 62.0459 114.203 62.0458H210.641C211.56 62.0458 212.441 62.398 213.091 63.025C213.741 63.6519 214.106 64.5022 214.106 65.3888V150.017C214.106 150.456 214.016 150.89 213.842 151.296C213.668 151.702 213.413 152.07 213.091 152.381C212.769 152.691 212.387 152.937 211.967 153.105C211.547 153.273 211.096 153.36 210.641 153.36V153.36Z"
          stroke="#263238"
          strokeWidth="0.7043"
          strokeMiterlimit="10"
        />
        <path
          d="M94.7326 61.7784H69.652C69.0536 61.7784 68.4796 61.549 68.0564 61.1407C67.6333 60.7324 67.3955 60.1787 67.3955 59.6013V59.6013C67.3947 59.3149 67.4525 59.0311 67.5655 58.7663C67.6785 58.5015 67.8446 58.2608 68.0543 58.058C68.2639 57.8552 68.5129 57.6943 68.7871 57.5845C69.0612 57.4747 69.3552 57.4182 69.652 57.4182H94.7326C95.3327 57.4182 95.9082 57.6482 96.3326 58.0576C96.7569 58.467 96.9953 59.0223 96.9953 59.6013V59.6013C96.9953 59.8877 96.9367 60.1713 96.8229 60.4358C96.7091 60.7003 96.5424 60.9406 96.3322 61.1428C96.122 61.3451 95.8725 61.5053 95.598 61.6144C95.3235 61.7234 95.0295 61.7792 94.7326 61.7784V61.7784Z"
          fill="white"
        />
        <path
          d="M210.641 168.124H53.9122C52.9932 168.124 52.1119 167.772 51.4621 167.145C50.8123 166.518 50.4473 165.667 50.4473 164.781V80.1708C50.4473 79.2841 50.8123 78.4338 51.4621 77.8069C52.1119 77.18 52.9932 76.8278 53.9122 76.8278H54.3376C55.0315 76.8272 55.7093 76.6264 56.284 76.2511C56.8586 75.8759 57.3037 75.3433 57.5621 74.722L59.948 68.9402C60.2131 68.2712 60.7011 67.7063 61.3352 67.3341C62.0141 66.9443 62.7895 66.7388 63.5794 66.7393H103.864C104.405 66.7387 104.942 66.8335 105.448 67.0188C106.153 67.2925 106.74 67.7898 107.113 68.4286L111.213 75.1741C111.518 75.6775 111.955 76.095 112.48 76.3851C113.004 76.6752 113.598 76.8278 114.203 76.8278H210.641C211.56 76.8278 212.441 77.18 213.091 77.8069C213.741 78.4338 214.106 79.2841 214.106 80.1708V164.781C214.106 165.667 213.741 166.518 213.091 167.145C212.441 167.772 211.56 168.124 210.641 168.124V168.124Z"
          fill="#263238"
          stroke="#263238"
          strokeWidth="0.7043"
          strokeMiterlimit="10"
        />
        <path
          d="M106.749 44.3317L81.8658 41.3575C81.2715 41.2847 80.7311 40.988 80.3626 40.5322C79.9942 40.0764 79.8275 39.4985 79.899 38.9246C79.9744 38.3504 80.283 37.8286 80.7571 37.4739C81.2311 37.1192 81.8317 36.9606 82.4268 37.033L107.304 40.0072C107.899 40.0786 108.44 40.3749 108.809 40.831C109.178 41.2872 109.344 41.8659 109.271 42.4401V42.4401C109.234 42.7242 109.14 42.9985 108.993 43.2473C108.847 43.4962 108.651 43.7147 108.416 43.8904C108.182 44.0661 107.914 44.1955 107.628 44.2713C107.342 44.347 107.043 44.3675 106.749 44.3317V44.3317Z"
          fill="#15C39A"
        />
        <path
          opacity="0.5"
          d="M106.749 44.3317L81.8658 41.3575C81.2715 41.2847 80.7311 40.988 80.3626 40.5322C79.9942 40.0764 79.8275 39.4985 79.899 38.9246C79.9744 38.3504 80.283 37.8286 80.7571 37.4739C81.2311 37.1192 81.8317 36.9606 82.4268 37.033L107.304 40.0072C107.899 40.0786 108.44 40.3749 108.809 40.831C109.178 41.2872 109.344 41.8659 109.271 42.4401V42.4401C109.234 42.7242 109.14 42.9985 108.993 43.2473C108.847 43.4962 108.651 43.7147 108.416 43.8904C108.182 44.0661 107.914 44.1955 107.628 44.2713C107.342 44.347 107.043 44.3675 106.749 44.3317V44.3317Z"
          fill="white"
        />
        <path
          d="M94.7326 76.5482H69.652C69.3552 76.5482 69.0612 76.4917 68.7871 76.3819C68.5129 76.2722 68.2639 76.1113 68.0543 75.9085C67.8446 75.7057 67.6785 75.465 67.5655 75.2002C67.4525 74.9353 67.3947 74.6516 67.3955 74.3652V74.3652C67.3955 73.7872 67.6331 73.2329 68.0561 72.8236C68.4791 72.4144 69.053 72.1837 69.652 72.1821H94.7326C95.3327 72.1821 95.9082 72.4121 96.3326 72.8215C96.7569 73.2309 96.9953 73.7862 96.9953 74.3652V74.3652C96.9953 74.9442 96.7569 75.4994 96.3326 75.9088C95.9082 76.3182 95.3327 76.5482 94.7326 76.5482V76.5482Z"
          fill="#15C39A"
        />
        <path
          opacity="0.5"
          d="M94.7326 76.5482H69.652C69.3552 76.5482 69.0612 76.4917 68.7871 76.3819C68.5129 76.2722 68.2639 76.1113 68.0543 75.9085C67.8446 75.7057 67.6785 75.465 67.5655 75.2002C67.4525 74.9353 67.3947 74.6516 67.3955 74.3652V74.3652C67.3955 73.7872 67.6331 73.2329 68.0561 72.8236C68.4791 72.4144 69.053 72.1837 69.652 72.1821H94.7326C95.3327 72.1821 95.9082 72.4121 96.3326 72.8215C96.7569 73.2309 96.9953 73.7862 96.9953 74.3652V74.3652C96.9953 74.9442 96.7569 75.4994 96.3326 75.9088C95.9082 76.3182 95.3327 76.5482 94.7326 76.5482V76.5482Z"
          fill="white"
        />
        <path
          d="M215.764 102.275H48.7823C46.9879 102.275 45.5332 103.679 45.5332 105.41V202.898C45.5332 204.63 46.9879 206.033 48.7823 206.033H215.764C217.559 206.033 219.013 204.63 219.013 202.898V105.41C219.013 103.679 217.559 102.275 215.764 102.275Z"
          fill="white"
        />
        <g style={{ mixBlendMode: "multiply" }}>
          <path
            d="M219.014 130.203C199.784 147.543 177.935 161.963 154.216 172.972C119.166 189.145 79.1037 200.293 55.7617 206.033H215.758C216.185 206.034 216.609 205.953 217.004 205.796C217.398 205.639 217.757 205.408 218.06 205.117C218.362 204.826 218.602 204.48 218.766 204.099C218.929 203.718 219.014 203.31 219.014 202.898V130.203Z"
            fill="#15C39A"
          />
        </g>
        <path
          opacity="0.2"
          d="M219.014 130.203C199.784 147.543 177.935 161.963 154.216 172.972C119.166 189.145 79.1037 200.293 55.7617 206.033H215.758C216.185 206.034 216.609 205.953 217.004 205.796C217.398 205.639 217.757 205.408 218.06 205.117C218.362 204.826 218.602 204.48 218.766 204.099C218.929 203.718 219.014 203.31 219.014 202.898V130.203Z"
          fill="white"
        />
        <path
          d="M215.764 102.275H48.7823C46.9879 102.275 45.5332 103.679 45.5332 105.41V202.898C45.5332 204.63 46.9879 206.033 48.7823 206.033H215.764C217.559 206.033 219.013 204.63 219.013 202.898V105.41C219.013 103.679 217.559 102.275 215.764 102.275Z"
          stroke="#263238"
          strokeWidth="0.7043"
          strokeMiterlimit="10"
        />
        <path
          d="M48.043 111.388V107.224C48.0446 106.392 48.3883 105.595 48.9986 105.007C49.6089 104.419 50.436 104.089 51.2983 104.089H58.3576"
          stroke="#263238"
          strokeWidth="0.7043"
          strokeMiterlimit="10"
        />
        <path
          d="M48.043 174.625V116.462"
          stroke="#263238"
          strokeWidth="0.7043"
          strokeMiterlimit="10"
        />
        <path
          d="M216.497 194.469V201.054C216.498 201.466 216.415 201.875 216.252 202.256C216.089 202.637 215.85 202.983 215.548 203.275C215.246 203.566 214.888 203.798 214.493 203.956C214.098 204.114 213.675 204.195 213.248 204.195H207.558"
          stroke="#263238"
          strokeWidth="0.7043"
          strokeMiterlimit="10"
        />
        <path
          d="M216.498 109.925V189.574"
          stroke="#263238"
          strokeWidth="0.7043"
          strokeMiterlimit="10"
        />
        <path
          d="M158.063 140.214H106.478C105.548 140.214 104.795 140.941 104.795 141.838V167.231C104.795 168.128 105.548 168.855 106.478 168.855H158.063C158.993 168.855 159.747 168.128 159.747 167.231V141.838C159.747 140.941 158.993 140.214 158.063 140.214Z"
          fill="#263238"
          stroke="#263238"
          strokeWidth="0.7043"
          strokeMiterlimit="10"
        />
        <path
          d="M158.063 140.214H106.478C105.548 140.214 104.795 140.941 104.795 141.838V147.298C104.795 148.195 105.548 148.922 106.478 148.922H158.063C158.993 148.922 159.747 148.195 159.747 147.298V141.838C159.747 140.941 158.993 140.214 158.063 140.214Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.7043"
          strokeMiterlimit="10"
        />
        <path
          d="M57.7402 53.07L58.8685 55.0984L61.2668 56.7639C61.4176 56.8708 61.5982 56.9313 61.7851 56.9377C61.9721 56.9441 62.1567 56.8959 62.3149 56.7996L62.5245 56.6687C62.677 56.5794 62.7905 56.4396 62.8438 56.2753C62.8972 56.1111 62.8867 55.9338 62.8143 55.7765L61.4518 52.7547L62.3704 53.4447C62.5162 53.5532 62.7001 53.6026 62.883 53.5826C63.066 53.5627 63.2337 53.4748 63.3507 53.3376V53.3376C63.4522 53.2153 63.5076 53.0631 63.5076 52.9064C63.5076 52.7496 63.4522 52.5975 63.3507 52.4751L61.2113 49.8638L58.5356 50.2623L57.7402 53.07Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.7043"
          strokeMiterlimit="10"
        />
        <path
          d="M62.5489 56.6686L60.4033 53.9502"
          stroke="#263238"
          strokeWidth="0.35215"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M61.2915 56.7639L59.6084 54.6106"
          stroke="#263238"
          strokeWidth="0.35215"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M93.043 33.3926L96.3969 33.5829L100.478 36.0991C100.478 36.0991 100.706 36.8664 100.139 36.9735C99.5721 37.0806 96.5079 35.992 96.5079 35.992L93.2341 35.9504L93.043 33.3926Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.7043"
          strokeMiterlimit="10"
        />
        <path
          d="M93.2168 35.5518L96.7372 37.0865C96.7372 37.0865 97.3044 36.3191 96.5091 35.9919L95.35 35.2246"
          fill="white"
        />
        <path
          d="M93.2168 35.5518L96.7372 37.0865C96.7372 37.0865 97.3044 36.3191 96.5091 35.9919L95.35 35.2246"
          stroke="#263238"
          strokeWidth="0.7043"
          strokeLinejoin="round"
        />
        <path
          d="M217.592 93.5607L216.137 89.3611L214.503 87.2019C214.476 87.1624 214.436 87.1327 214.39 87.1173C214.344 87.1018 214.294 87.1015 214.247 87.1165C214.201 87.1314 214.161 87.1607 214.134 87.1998C214.106 87.2389 214.093 87.2856 214.096 87.3327V93.5547L215.329 95.1192L217.592 93.5607Z"
          fill="white"
        />
        <path
          d="M241.304 111.924L229.097 112.34C228.646 112.355 228.201 112.246 227.812 112.026C227.423 111.806 227.108 111.484 226.902 111.097L217.592 93.5608C217.592 93.5608 215.496 94.6553 215.336 95.1193C215.175 95.5833 222.229 112.649 224.393 117.89C224.575 118.33 224.893 118.704 225.305 118.962C225.717 119.22 226.202 119.348 226.692 119.329L240.336 118.77"
          fill="white"
        />
        <path
          d="M241.304 111.924L229.097 112.34C228.646 112.355 228.201 112.246 227.812 112.026C227.423 111.806 227.108 111.484 226.902 111.097L217.592 93.5608C217.592 93.5608 215.496 94.6553 215.336 95.1193C215.175 95.5833 222.229 112.649 224.393 117.89C224.575 118.33 224.893 118.704 225.305 118.962C225.717 119.22 226.202 119.348 226.692 119.329L240.336 118.77"
          stroke="#263238"
          strokeWidth="0.7043"
          strokeMiterlimit="10"
        />
        <path
          d="M218.485 95.7439L216.574 96.5053"
          stroke="#263238"
          strokeWidth="0.528225"
          strokeMiterlimit="10"
        />
        <g opacity="0.5">
          <g opacity="0.5">
            <path
              d="M241.304 111.924L229.097 112.34C228.646 112.355 228.201 112.246 227.812 112.026C227.423 111.806 227.108 111.484 226.902 111.097L217.592 93.5608C217.592 93.5608 215.496 94.6553 215.336 95.1193C215.175 95.5833 222.229 112.649 224.393 117.89C224.575 118.33 224.893 118.704 225.305 118.962C225.717 119.22 226.202 119.348 226.692 119.329L240.336 118.77"
              fill="#15C39A"
            />
            <path
              d="M241.304 111.924L229.097 112.34C228.646 112.355 228.201 112.246 227.812 112.026C227.423 111.806 227.108 111.484 226.902 111.097L217.592 93.5608C217.592 93.5608 215.496 94.6553 215.336 95.1193C215.175 95.5833 222.229 112.649 224.393 117.89C224.575 118.33 224.893 118.704 225.305 118.962C225.717 119.22 226.202 119.348 226.692 119.329L240.336 118.77"
              stroke="#263238"
              strokeWidth="0.7043"
              strokeMiterlimit="10"
            />
          </g>
          <path
            opacity="0.5"
            d="M218.486 95.7439L216.575 96.5053"
            stroke="#263238"
            strokeWidth="0.528225"
            strokeMiterlimit="10"
          />
        </g>
        <path
          d="M217.592 93.5607L216.137 89.3611L214.503 87.2019C214.476 87.1624 214.436 87.1327 214.39 87.1173C214.344 87.1018 214.294 87.1015 214.247 87.1165C214.201 87.1314 214.161 87.1607 214.134 87.1998C214.106 87.2389 214.093 87.2856 214.096 87.3327V93.5547L215.329 95.1192L217.592 93.5607Z"
          fill="#15C39A"
        />
        <path
          opacity="0.5"
          d="M217.591 93.5607L216.136 89.3611L214.502 87.2019C214.475 87.1624 214.436 87.1327 214.389 87.1173C214.343 87.1018 214.293 87.1015 214.246 87.1165C214.2 87.1314 214.16 87.1607 214.133 87.1998C214.105 87.2389 214.092 87.2856 214.095 87.3327V93.5547L215.328 95.1192L217.591 93.5607Z"
          fill="white"
        />
        <path
          d="M217.592 93.5607L216.137 89.3611L214.503 87.2019C214.476 87.1624 214.436 87.1327 214.39 87.1173C214.344 87.1018 214.294 87.1015 214.247 87.1165C214.201 87.1314 214.161 87.1607 214.134 87.1998C214.106 87.2389 214.093 87.2856 214.096 87.3327V93.5547L215.329 95.1192L217.592 93.5607Z"
          stroke="#263238"
          strokeWidth="0.7043"
          strokeMiterlimit="10"
        />
        <path
          d="M245.083 100.842C244.497 101.558 244.076 102.388 243.85 103.275C243.671 104.417 243.955 108.813 243.955 108.813L237.604 109.503C237.604 109.503 238.085 105.035 238.11 104.292C238.313 103.37 238.718 102.5 239.298 101.741C239.877 100.982 240.618 100.351 241.47 99.8899C243.653 98.8549 245.508 99.3605 245.083 100.842Z"
          fill="white"
        />
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path
            d="M245.04 100.889C243.887 100.586 242.487 100.247 241.304 99.9731C240.49 100.441 239.785 101.065 239.232 101.807C238.679 102.549 238.291 103.393 238.092 104.286C238.092 104.571 238.005 105.404 237.919 106.32C238.535 106.183 239.516 105.975 240.243 105.85C240.82 105.76 241.387 105.623 241.939 105.44L241.723 109.009H241.865L243.936 108.783C243.936 108.783 243.653 104.387 243.832 103.245C244.057 102.386 244.469 101.583 245.04 100.889V100.889Z"
            fill="#15C39A"
          />
        </g>
        <path
          d="M245.083 100.842C244.497 101.558 244.076 102.388 243.85 103.275C243.671 104.417 243.955 108.813 243.955 108.813L237.604 109.503C237.604 109.503 238.085 105.035 238.11 104.292C238.313 103.37 238.718 102.5 239.298 101.741C239.877 100.982 240.618 100.351 241.47 99.8899C243.653 98.8549 245.508 99.3605 245.083 100.842Z"
          stroke="#263238"
          strokeWidth="0.707089"
          strokeMiterlimit="10"
        />
        <path
          d="M246.402 101.597C251.667 94.8874 238.973 87.9158 235.637 94.584C235.173 95.4063 234.805 96.2762 234.54 97.1775C234.034 97.4749 232.69 98.272 232.444 98.4921C232.197 98.7122 233.06 99.2773 233.535 99.5568C232.629 101.704 232.764 103.965 233.023 104.476C233.473 105.357 235.693 105.368 237.154 105.107C238.615 104.845 239.195 103.495 239.62 103.126C240.046 102.757 242.167 103.227 242.913 103.441C243.659 103.655 245.379 102.924 246.402 101.597Z"
          fill="white"
        />
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path
            d="M241.192 100.955C241.192 100.955 241.303 101.46 240.243 101.871C239.182 102.281 238.973 101.972 238.658 102.686C238.525 103.155 238.254 103.578 237.88 103.903C237.505 104.229 237.041 104.444 236.543 104.524C235.539 104.708 234.524 104.833 233.504 104.898C234.342 105.35 236.007 105.315 237.203 105.107C238.658 104.851 239.244 103.495 239.669 103.126C240.095 102.757 242.216 103.227 242.962 103.441C243.137 103.475 243.317 103.475 243.492 103.441C243.77 102.843 243.937 102.203 243.985 101.549L241.192 100.955Z"
            fill="#15C39A"
          />
        </g>
        <path
          d="M246.402 101.597C251.667 94.8874 238.973 87.9158 235.637 94.584C235.173 95.4063 234.805 96.2762 234.54 97.1775C234.034 97.4749 232.69 98.272 232.444 98.4921C232.197 98.7122 233.06 99.2773 233.535 99.5568C232.629 101.704 232.764 103.965 233.023 104.476C233.473 105.357 235.693 105.368 237.154 105.107C238.615 104.845 239.195 103.495 239.62 103.126C240.046 102.757 242.167 103.227 242.913 103.441C243.659 103.655 245.379 102.924 246.402 101.597Z"
          stroke="#263238"
          strokeWidth="0.7043"
          strokeMiterlimit="10"
        />
        <path
          d="M236.119 97.7662C235.983 98.0517 235.755 98.2302 235.607 98.1647C235.459 98.0993 235.447 97.8138 235.607 97.5283C235.767 97.2427 235.971 97.0643 236.119 97.1297C236.267 97.1952 236.254 97.4807 236.119 97.7662Z"
          fill="#263238"
        />
        <path
          d="M235.995 95.9341C235.995 95.9341 236.612 95.4404 236.735 96.5766"
          stroke="#263238"
          strokeWidth="0.35215"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M233.411 100.711C233.411 100.711 233.411 101.306 234.521 101.306"
          stroke="#263238"
          strokeWidth="0.35215"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M231.907 139.637L231.42 145.454C231.42 145.454 228.047 168.618 228.208 171.277C228.368 173.935 228.732 201.405 228.732 201.405L232.795 202.03L237.357 171.562L243.763 140.601L231.907 139.637Z"
          fill="#15C39A"
          stroke="#263238"
          strokeWidth="0.7043"
          strokeMiterlimit="10"
        />
        <path
          opacity="0.2"
          d="M231.907 139.637L231.42 145.454C231.42 145.454 228.047 168.618 228.208 171.277C228.368 173.935 228.732 201.405 228.732 201.405L232.795 202.03L237.357 171.562L243.763 140.601L231.907 139.637Z"
          fill="#263238"
        />
        <path
          d="M237.05 160.796C237.05 160.796 235.49 169.653 234.312 173.995C233.135 178.337 233.141 182.668 233.141 187.379C233.141 192.09 231.384 200.775 231.384 200.775"
          stroke="#263238"
          strokeWidth="0.528225"
          strokeMiterlimit="10"
        />
        <path
          d="M240.15 138.209C242.825 136.853 245.698 135.955 246.518 137.757C248.146 141.362 246.198 146.841 246.198 146.841L247.684 172.109L250.403 202.595L246.34 201.964C246.34 201.964 240.1 176.238 239.126 174.667C238.152 173.097 236.617 151.07 236.617 151.07L233.534 148.363"
          fill="#15C39A"
        />
        <path
          d="M240.15 138.209C242.825 136.853 245.698 135.955 246.518 137.757C248.146 141.362 246.198 146.841 246.198 146.841L247.684 172.109L250.403 202.595L246.34 201.964C246.34 201.964 240.1 176.238 239.126 174.667C238.152 173.097 236.617 151.07 236.617 151.07L233.534 148.363"
          stroke="#263238"
          strokeWidth="0.7043"
          strokeMiterlimit="10"
        />
        <g opacity="0.2">
          <path
            opacity="0.2"
            d="M240.15 138.209C242.825 136.853 245.698 135.955 246.518 137.757C248.146 141.362 246.198 146.84 246.198 146.84L247.684 172.109L250.403 202.595L246.34 201.964C246.34 201.964 240.1 176.237 239.126 174.667C238.152 173.097 236.617 151.07 236.617 151.07L233.534 148.363"
            fill="#263238"
          />
        </g>
        <path
          d="M243.319 157.351C243.412 163.21 244.028 166.03 244.509 167.577C245.095 169.462 244.898 173.043 244.898 174.715C244.898 176.386 244.115 174.34 245.878 182.073C247.641 189.806 248.806 200.549 248.806 200.549"
          stroke="#263238"
          strokeWidth="0.528225"
          strokeMiterlimit="10"
        />
        <path
          d="M243.319 144.58C243.319 144.58 243.511 144.2 243.319 153.063C243.319 153.539 243.319 153.997 243.319 154.443"
          stroke="#263238"
          strokeWidth="0.528225"
          strokeMiterlimit="10"
        />
        <path
          d="M234.299 142.897L234.169 145.55L233.534 147.394L234.484 148.464"
          stroke="#263238"
          strokeWidth="0.528225"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M233.055 144.479C233.055 144.479 234.645 146.014 238.776 145.55"
          stroke="#263238"
          strokeWidth="0.528225"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M242.814 143.152C245.896 142.558 246.094 141.737 247.16 141.047C247.18 139.922 246.971 138.804 246.544 137.757C245.736 135.973 242.845 136.853 240.175 138.209L238.88 140.184L231.932 139.637L231.71 142.266C235.277 143.447 239.094 143.752 242.814 143.152V143.152Z"
          fill="#263238"
        />
        <path
          d="M232.604 141.457L232.536 142.504L234.299 142.897L234.435 141.523L232.604 141.457Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.528225"
          strokeMiterlimit="10"
        />
        <path
          d="M244.91 143.01L244.614 140.404L245.508 140.119L245.958 142.433"
          fill="#15C39A"
        />
        <path
          d="M244.91 143.01L244.614 140.404L245.508 140.119L245.958 142.433"
          stroke="#263238"
          strokeWidth="0.7043"
          strokeMiterlimit="10"
        />
        <path
          d="M238.017 143.878L237.419 140.839H238.917L239.515 143.444"
          fill="#15C39A"
        />
        <path
          d="M238.017 143.878L237.419 140.839H238.917L239.515 143.444"
          stroke="#263238"
          strokeWidth="0.7043"
          strokeMiterlimit="10"
        />
        <g opacity="0.2">
          <path
            opacity="0.2"
            d="M244.909 143.01L244.613 140.404L245.507 140.119L245.957 142.433"
            fill="#263238"
          />
          <path
            opacity="0.2"
            d="M238.016 143.878L237.418 140.839H238.916L239.514 143.444"
            fill="#263238"
          />
        </g>
        <path
          d="M231.113 116.581C231.113 116.581 230.601 119.882 229.066 125.504C227.531 131.125 225.983 137.864 225.983 137.864L226.84 141.326L229.399 137.698L231.113 116.581Z"
          fill="#263238"
          stroke="#263238"
          strokeWidth="0.7043"
          strokeMiterlimit="10"
        />
        <path
          d="M235.384 111.804C235.384 111.804 231.796 112.625 231.457 114.273C231.274 115.031 231.158 115.804 231.111 116.581L236.58 112.298L243.417 109.657L235.384 111.804Z"
          fill="#263238"
          stroke="#263238"
          strokeWidth="0.7043"
          strokeMiterlimit="10"
        />
        <path
          d="M236.364 110.532C236.364 110.532 233.448 113.714 232.289 115.023C231.13 116.331 231.123 130.375 231.123 132.44C231.123 134.504 229.964 140.107 231.9 141.231C233.836 142.356 245.68 141.981 246.845 140.107C248.01 138.233 248.781 115.772 248.59 113.714C248.445 112.791 248.068 111.915 247.492 111.165C246.916 110.414 246.158 109.811 245.285 109.407C243.935 108.807 236.364 110.532 236.364 110.532Z"
          fill="white"
        />
        <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
          <path
            d="M245.285 109.407C245.124 109.345 244.953 109.308 244.779 109.3L243.941 109.723C245.352 110.838 246.323 112.388 246.691 114.113C247.081 116.618 247.045 119.167 246.586 121.661C246.167 124.415 239.397 121.661 240.667 122.684C241.937 123.707 246.691 125.438 246.906 126.354C247.122 127.27 244.551 128.799 243.626 128.906C242.702 129.013 244.243 131.048 245.211 132.779C246.179 134.51 245.828 136.943 243.626 138.596C241.425 140.25 236.314 141.065 234.107 141.148C233.392 141.148 232.948 141.326 232.812 141.529C236.203 142.26 245.803 141.785 246.845 140.107C248.01 138.233 248.781 115.772 248.59 113.714C248.444 112.791 248.067 111.916 247.491 111.165C246.915 110.414 246.158 109.811 245.285 109.407V109.407Z"
            fill="#15C39A"
          />
        </g>
        <path
          d="M236.364 110.532C236.364 110.532 233.448 113.714 232.289 115.023C231.13 116.331 231.123 130.375 231.123 132.44C231.123 134.504 229.964 140.107 231.9 141.231C233.836 142.356 245.68 141.981 246.845 140.107C248.01 138.233 248.781 115.772 248.59 113.714C248.445 112.791 248.068 111.915 247.492 111.165C246.916 110.414 246.158 109.811 245.285 109.407C243.935 108.807 236.364 110.532 236.364 110.532Z"
          stroke="#263238"
          strokeWidth="0.7043"
          strokeMiterlimit="10"
        />
        <path
          d="M242.326 124.778C239.2 123.838 236.463 121.209 236.463 121.209C237.156 124.333 239.04 127.092 241.74 128.942"
          fill="white"
        />
        <path
          d="M242.326 124.778C239.2 123.838 236.463 121.209 236.463 121.209C237.156 124.333 239.04 127.092 241.74 128.942"
          stroke="#263238"
          strokeWidth="0.528225"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M244.706 107.349L235.39 109.407L234.811 112.031L245.285 109.407L244.706 107.349Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.7043"
          strokeMiterlimit="10"
        />
        <path
          d="M233.338 205.956L232.796 202.006L228.733 201.381L225.348 203.707L222.314 205.313V205.956H233.338Z"
          fill="#263238"
          stroke="#263238"
          strokeWidth="0.7043"
          strokeMiterlimit="10"
        />
        <path
          d="M250.909 206.265L250.403 202.595L246.34 201.964L242.955 204.29L239.922 205.902V206.265H250.909Z"
          fill="#263238"
          stroke="#263238"
          strokeWidth="0.7043"
          strokeMiterlimit="10"
        />
        <path
          d="M215.816 90.1346L213.313 88.7367C213.313 88.7367 212.339 88.6237 212.758 89.6706L215.261 91.5325"
          fill="white"
        />
        <path
          d="M215.816 90.1346L213.313 88.7367C213.313 88.7367 212.339 88.6237 212.758 89.6706L215.261 91.5325"
          stroke="#263238"
          strokeWidth="0.7043"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M215.446 93.769C215.328 93.482 215.154 93.2195 214.934 92.9957L214.466 92.5079L211.919 89.4623C211.919 89.4623 211.13 88.9508 211.303 90.0572L212.881 93.0314L210.076 90.4914C210.076 90.4914 209.546 90.0691 209.546 90.6639C209.546 91.2587 212.092 93.882 212.092 93.882L209.281 92.609C209.281 92.609 208.664 93.0314 209.108 93.4537C209.552 93.876 211.913 94.7267 211.913 94.7267L209.811 94.6434C209.811 94.6434 209.194 95.149 209.632 95.3215C210.07 95.494 212.53 95.9937 212.53 95.9937C212.842 96.25 213.199 96.4513 213.584 96.5885L214.201 97.689L216.562 96.5409L215.446 93.769Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.7043"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M240.657 120.174L226.588 123.356C225.867 123.479 225.126 123.356 224.488 123.011C223.851 122.665 223.357 122.116 223.092 121.459L214.214 97.6651L216.575 96.5171L226.384 114.047C226.607 114.448 226.957 114.769 227.383 114.963C227.81 115.157 228.29 115.213 228.752 115.124L239.51 112.191"
          fill="white"
        />
        <path
          d="M240.657 120.174L226.588 123.356C225.867 123.479 225.126 123.356 224.488 123.011C223.851 122.665 223.357 122.116 223.092 121.459L214.214 97.6651L216.575 96.5171L226.384 114.047C226.607 114.448 226.957 114.769 227.383 114.963C227.81 115.157 228.29 115.213 228.752 115.124L239.51 112.191"
          stroke="#263238"
          strokeWidth="0.7043"
          strokeMiterlimit="10"
        />
        <path
          d="M217.9 98.7598L215.162 100.08"
          stroke="#263238"
          strokeWidth="0.528225"
          strokeMiterlimit="10"
        />
        <path
          d="M238.906 94.6551C238.906 94.6551 237.408 96.1006 238.154 97.1832C238.596 97.8084 238.933 98.497 239.153 99.2235C239.153 99.2235 238.536 100.669 239.029 100.669C239.523 100.669 239.276 99.5863 240.151 99.8302C241.027 100.074 241.896 101.514 241.15 101.995C240.592 102.296 240.001 102.536 239.387 102.709C239.309 102.732 239.239 102.777 239.187 102.838C239.135 102.898 239.102 102.972 239.093 103.051C239.084 103.129 239.098 103.208 239.135 103.279C239.172 103.349 239.23 103.408 239.301 103.447C240.112 103.975 241.046 104.302 242.019 104.399C242.816 104.415 243.611 104.335 244.387 104.161C244.387 104.161 246.853 100.913 248.629 100.068C250.404 99.2235 249.627 95.3749 249.627 94.893C249.627 94.4112 247.883 93.3286 247.883 93.3286C247.883 93.3286 248.382 92.246 246.138 89.8428C243.894 87.4397 239.652 86.9519 239.529 87.7966C239.485 88.0202 239.509 88.2511 239.598 88.462C239.686 88.6729 239.836 88.8548 240.028 88.9863C240.028 88.9863 236.286 87.4218 234.541 89.1052C232.796 90.7886 238.906 94.6551 238.906 94.6551Z"
          fill="#263238"
          stroke="#263238"
          strokeWidth="0.7043"
          strokeMiterlimit="10"
        />
        <path
          d="M238.905 94.6553C238.905 94.6553 241.939 98.2243 246.433 98.5693"
          stroke="white"
          strokeWidth="0.35215"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M237.832 95.7439C237.832 95.7439 239.244 99.7055 245.082 100.836"
          stroke="white"
          strokeWidth="0.35215"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_852_163"
          x="0"
          y="2.55933"
          width="267.634"
          height="214.075"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2.8172" dy="2.8172" />
          <feGaussianBlur stdDeviation="1.4086" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_852_163"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_852_163"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default Folders;
