import React from "react";

interface StudyingGirlProps {
  width?: number;
  height?: number;
  className?: string;
}

const StudyingGirl = ({
  width = 527,
  height = 544,
  className,
}: StudyingGirlProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 527 544"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_246_492)">
        <g filter="url(#filter0_d_246_492)">
          <path
            d="M254.133 538C362.558 538 450.454 519.47 450.454 496.612C450.454 473.754 362.558 455.224 254.133 455.224C145.708 455.224 57.8125 473.754 57.8125 496.612C57.8125 519.47 145.708 538 254.133 538Z"
            fill="#15C39A"
          />
          <path
            opacity="0.5"
            d="M254.133 538C362.558 538 450.454 519.47 450.454 496.612C450.454 473.754 362.558 455.224 254.133 455.224C145.708 455.224 57.8125 473.754 57.8125 496.612C57.8125 519.47 145.708 538 254.133 538Z"
            fill="white"
          />
        </g>
        <g filter="url(#filter1_d_246_492)">
          <path
            d="M307.29 199.229C307.29 199.229 322.754 234.02 322.754 235.856C322.754 237.693 309.033 245.56 306.419 247.214C304.392 248.431 302.303 249.529 300.161 250.504L301.729 266.622C301.729 266.622 325.353 260.943 335.255 255.265C345.158 249.586 344.809 243.173 342.515 233.652C340.221 224.132 324.961 187.336 324.961 187.336L307.29 199.229Z"
            fill="white"
          />
          <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
            <path
              d="M335.211 255.265C345.113 249.586 344.765 243.173 342.471 233.652C340.177 224.132 324.916 187.336 324.916 187.336L322.448 188.989L321.664 190.519C321.664 190.519 335.908 227.377 336.764 239.652C337.345 247.902 321.591 258.188 311.181 264.066C318.905 261.907 329.417 258.586 335.211 255.265Z"
              fill="#15C39A"
            />
          </g>
          <path
            d="M307.29 199.229C307.29 199.229 322.754 234.02 322.754 235.856C322.754 237.693 309.033 245.56 306.419 247.214C304.392 248.431 302.303 249.529 300.161 250.504L301.729 266.622C301.729 266.622 325.353 260.943 335.255 255.265C345.158 249.586 344.809 243.173 342.515 233.652C340.221 224.132 324.961 187.336 324.961 187.336L307.29 199.229Z"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M195.721 191.545C195.721 191.545 184.439 235.122 184.105 242.821C183.771 250.52 206.509 265.703 207.337 266.622C208.164 267.54 206.044 240.142 206.044 240.142L213.754 200.346L195.721 191.545Z"
            fill="white"
          />
          <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
            <path
              d="M200.889 195.111L200.642 193.933L195.72 191.545C195.72 191.545 184.438 235.122 184.104 242.821C183.77 250.52 206.508 265.703 207.336 266.622C207.67 266.989 207.539 262.887 207.263 257.821C200.497 251.775 190.783 243.265 189.651 239.683"
              fill="#15C39A"
            />
          </g>
          <path
            d="M195.721 191.545C195.721 191.545 184.439 235.122 184.105 242.821C183.771 250.52 206.509 265.703 207.337 266.622C208.164 267.54 206.044 240.142 206.044 240.142L213.754 200.346L195.721 191.545Z"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M248.703 147.77C248.703 147.77 244.347 151.076 235.853 151.443C227.359 151.81 226.952 145.321 216.397 145.321C203.605 145.321 187.561 187.979 187.561 187.979C187.561 187.979 195.546 196.229 203.372 199.703C207.702 201.703 212.253 203.117 216.919 203.912L218.139 231.938H304.299L305.33 203.912L332.264 185.499C332.264 185.499 319.051 160.596 312.633 151.259C306.215 141.923 295.427 145.397 292.131 147.77C288.835 150.142 279.064 150.708 275.463 150.157C270.765 149.061 266.123 147.718 261.553 146.132C261.553 146.132 256.006 141.264 248.703 147.77Z"
            fill="#15C39A"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M217.791 157.657L217.442 204.173"
            stroke="#263238"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M220.913 161.882C220.913 161.882 222.133 184.581 226.721 197.224"
            stroke="#263238"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M227.52 199.045C228.511 202.131 229.729 205.13 231.164 208.015"
            stroke="#263238"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M223.687 159.31C225.335 167.564 227.662 175.651 230.641 183.479"
            stroke="#263238"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M298.579 163.336C298.579 163.336 294.412 181.29 290.753 189.356"
            stroke="#263238"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M305.33 203.989L305.156 167.729"
            stroke="#263238"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M303.428 168.464C303.428 168.464 300.292 191.423 292.654 203.438"
            stroke="#263238"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M299.609 198.861C299.609 198.861 296.124 205.826 293.699 209.484"
            stroke="#263238"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M243.84 150.525C243.84 150.525 246.279 166.275 249.749 174.326C253.22 182.377 259.303 184.03 264.516 181.657C269.728 179.285 273.547 167.545 273.228 152.576"
            stroke="#263238"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M231.512 117.555C231.512 117.555 228.201 111.525 225.602 114.632C223.003 117.739 227.693 124.887 230.292 127.259C232.891 129.632 234.808 128.178 234.648 125.622C234.488 123.065 231.512 117.555 231.512 117.555Z"
            fill="#15C39A"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            opacity="0.5"
            d="M231.512 117.555C231.512 117.555 228.201 111.525 225.602 114.632C223.003 117.739 227.693 124.887 230.292 127.259C232.891 129.632 234.808 128.178 234.648 125.622C234.488 123.065 231.512 117.555 231.512 117.555Z"
            fill="white"
          />
          <path
            d="M231.512 117.555C231.512 117.555 228.201 111.525 225.602 114.632C223.003 117.739 227.693 124.887 230.292 127.259C232.891 129.632 234.808 128.178 234.648 125.622C234.488 123.065 231.512 117.555 231.512 117.555Z"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M284.059 117.555C284.059 117.555 287.355 111.525 289.969 114.632C292.582 117.739 287.878 124.887 285.279 127.259C282.68 129.632 280.749 128.178 280.923 125.622C281.097 123.065 284.059 117.555 284.059 117.555Z"
            fill="#15C39A"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            opacity="0.5"
            d="M284.059 117.555C284.059 117.555 287.355 111.525 289.969 114.632C292.582 117.739 287.878 124.887 285.279 127.259C282.68 129.632 280.749 128.178 280.923 125.622C281.097 123.065 284.059 117.555 284.059 117.555Z"
            fill="white"
          />
          <path
            d="M284.059 117.555C284.059 117.555 287.355 111.525 289.969 114.632C292.582 117.739 287.878 124.887 285.279 127.259C282.68 129.632 280.749 128.178 280.923 125.622C281.097 123.065 284.059 117.555 284.059 117.555Z"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M247.136 146.499C248.776 154.315 250.866 162.018 253.394 169.565C256.53 177.8 262.425 177.8 264.864 172.489C267.304 167.178 268.857 147.387 268.857 147.387C268.857 147.387 252.523 149.79 247.136 146.499Z"
            fill="white"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M231.861 119.576C231.861 119.576 236.377 136.412 244.929 146.315C253.481 156.218 256.544 158.56 261.089 157.841C265.634 157.122 270.469 150.892 277.235 137.943C284.001 124.994 284.495 115.06 285.061 114.142C285.627 113.223 283.609 94.9173 273.939 87.0346C264.269 79.152 253.437 75.6928 244.057 84.2948C234.678 92.8969 228.725 106.933 231.861 119.576Z"
            fill="white"
          />
          <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
            <path
              d="M273.896 86.9733C264.168 79.106 253.395 75.6315 244.015 84.2335C234.635 92.8356 228.726 106.933 231.862 119.575C231.862 119.575 232.718 122.82 234.417 127.229C234.412 126.664 234.486 126.102 234.635 125.56C236.711 118.626 242.839 105.203 263.457 111.356C279.835 116.223 277.831 131.407 276.307 139.504L277.192 137.82C283.973 124.825 284.452 114.938 285.018 114.019C285.585 113.101 283.625 94.856 273.896 86.9733Z"
              fill="#15C39A"
            />
          </g>
          <path
            d="M231.861 119.576C231.861 119.576 236.377 136.412 244.929 146.315C253.481 156.218 256.544 158.56 261.089 157.841C265.634 157.122 270.469 150.892 277.235 137.943C284.001 124.994 284.495 115.06 285.061 114.142C285.627 113.223 283.609 94.9173 273.939 87.0346C264.269 79.152 253.437 75.6928 244.057 84.2948C234.678 92.8969 228.725 106.933 231.861 119.576Z"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M261.045 128.361C260.506 131.936 260.215 135.548 260.174 139.167C260.348 142.826 261.393 143.928 259.825 144.295C258.257 144.662 256.529 142.091 256.529 142.091"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M243.666 131.3C244.673 132.067 245.875 132.494 247.117 132.527C248.358 132.56 249.579 132.196 250.621 131.484"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M265.736 131.3C266.741 132.065 267.941 132.492 269.18 132.525C270.418 132.558 271.637 132.195 272.677 131.484"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M254.758 125.132C254.758 125.132 251.448 116.514 240.515 120.54"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M263.862 124.076C263.862 124.076 266.636 115.642 276.538 120.586"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M256.864 131.928C256.864 136.642 251.739 140.453 245.408 140.453C239.077 140.453 233.938 136.642 233.938 131.928C233.938 127.213 238.729 125.254 245.06 125.254C251.39 125.254 256.864 127.229 256.864 131.928Z"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M283.275 131.928C283.275 136.642 278.135 140.453 271.805 140.453C265.474 140.453 260.349 136.642 260.349 131.928C260.349 127.213 265.126 125.254 271.456 125.254C277.787 125.254 283.275 127.229 283.275 131.928Z"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M136.874 278.76L136.162 464.316C136.162 464.901 136.382 465.461 136.775 465.875C137.167 466.288 137.698 466.52 138.253 466.52C138.794 466.52 139.313 466.298 139.701 465.901C140.089 465.504 140.314 464.962 140.329 464.393L145.353 279.02L136.874 278.76Z"
            fill="white"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M125.025 322.137L124.343 513.969C124.341 514.259 124.394 514.547 124.498 514.815C124.602 515.084 124.756 515.327 124.951 515.532C125.145 515.738 125.377 515.9 125.631 516.01C125.886 516.12 126.159 516.176 126.434 516.173C126.973 516.174 127.492 515.954 127.882 515.561C128.272 515.167 128.502 514.63 128.525 514.061L133.534 322.382L125.025 322.137Z"
            fill="white"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M380.223 278.76L380.934 464.316C380.936 464.605 380.884 464.891 380.78 465.158C380.677 465.426 380.524 465.669 380.331 465.874C380.138 466.079 379.909 466.241 379.656 466.352C379.403 466.463 379.132 466.52 378.858 466.52C378.316 466.521 377.795 466.299 377.405 465.902C377.014 465.505 376.786 464.964 376.767 464.393L371.758 279.02L380.223 278.76Z"
            fill="white"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M392.085 322.137L392.796 513.969C392.798 514.259 392.746 514.547 392.641 514.815C392.537 515.084 392.383 515.327 392.188 515.532C391.994 515.738 391.763 515.9 391.508 516.01C391.253 516.12 390.981 516.176 390.706 516.173C390.167 516.174 389.65 515.954 389.263 515.56C388.875 515.166 388.648 514.628 388.629 514.061L383.605 322.382L392.085 322.137Z"
            fill="white"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M207.526 330.112L207.105 500.377C207.059 500.802 207.171 501.229 207.417 501.569C207.664 501.908 208.025 502.134 208.426 502.199C208.818 502.142 209.174 501.929 209.422 501.605C209.67 501.281 209.792 500.869 209.762 500.454L212.913 330.311L207.526 330.112Z"
            fill="white"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M309.76 324.632L310.152 473.224C310.185 473.584 310.086 473.943 309.875 474.228C309.665 474.513 309.358 474.702 309.019 474.755C308.681 474.702 308.376 474.512 308.167 474.227C307.959 473.942 307.863 473.582 307.901 473.224L305.215 324.755L309.76 324.632Z"
            fill="white"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M205.943 324.632L206.335 473.224C206.369 473.584 206.27 473.943 206.059 474.228C205.848 474.513 205.542 474.702 205.203 474.755C204.865 474.702 204.559 474.512 204.351 474.227C204.143 473.942 204.047 473.582 204.085 473.224L201.398 324.755L205.943 324.632Z"
            fill="white"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M306.84 330.112L307.29 500.377C307.336 500.802 307.224 501.229 306.978 501.569C306.732 501.908 306.37 502.134 305.969 502.199C305.579 502.138 305.225 501.925 304.978 501.602C304.73 501.278 304.607 500.869 304.633 500.454L301.482 330.311L306.84 330.112Z"
            fill="white"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M256.472 342.035C292.157 342.035 321.085 335.066 321.085 326.469C321.085 317.872 292.157 310.902 256.472 310.902C220.787 310.902 191.859 317.872 191.859 326.469C191.859 335.066 220.787 342.035 256.472 342.035Z"
            fill="white"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M225.008 458.607C225.008 458.607 221.625 463.719 224.049 466.26C226.474 468.801 228.884 467.791 228.638 470.347C228.391 472.903 224.529 481.566 221.145 490.75C217.762 499.934 224.775 509.117 231.309 506.576C237.843 504.036 237.117 478.505 238.075 473.913C239.034 469.321 239.774 468.556 239.527 464.224C239.281 459.893 239.048 455.301 235.897 454.78C232.747 454.26 226.46 456.831 225.008 458.607Z"
            fill="white"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M235.388 499.535C231.032 497.791 224.353 498.143 220.593 498.541C221.856 504.311 226.691 508.367 231.308 506.576C233.239 505.826 234.503 503.102 235.388 499.535Z"
            fill="#15C39A"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M217.516 447.387C217.516 447.387 224.529 459.892 226.46 463.199C228.391 466.505 234.939 465.494 235.665 460.903C236.391 456.311 232.994 443.561 232.994 443.561L218.474 447.387H217.516Z"
            fill="white"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M200.817 427.49C200.817 427.49 198.872 430.811 199.845 433.107C200.817 435.403 203.475 442.03 205.173 445.352C206.872 448.673 214.611 450.709 223.555 449.178C232.499 447.648 237.596 443.301 237.843 439.474C238.09 435.648 236.391 430.551 235.171 424.428C233.951 418.306 230.815 418.566 226.459 418.566C222.103 418.566 204.447 423.923 200.817 427.49Z"
            fill="#15C39A"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M226.954 352.489C227.085 346.214 230.526 343.122 230.787 338.821C231.197 333.135 230.751 327.416 229.466 321.877L222.845 312.341C203.795 312.601 190.146 312.938 189.929 313.015C189.203 313.26 188.709 317.607 188.23 321.173C188.23 321.173 180.142 322.26 181.594 345.984C181.798 349.29 189.958 387.54 190.553 391.903C194.183 418.551 202.27 431.898 202.27 431.898C202.27 431.898 202.996 435.219 212.434 434.454C221.872 433.688 231.31 427.061 231.31 427.061C231.31 427.061 226.46 375.188 226.954 352.489Z"
            fill="#263238"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M186.923 317.285C186.923 317.285 176.919 318.816 170.791 326.193C164.664 333.571 169.862 338.438 181.768 337.122C193.674 335.806 207.41 322.964 207.991 322.275C208.571 321.586 195.576 312.862 186.923 317.285Z"
            fill="white"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M204.68 321.877C211.054 322.223 217.378 323.248 223.556 324.938C231.789 327.494 246.308 332.591 249.691 332.591C253.074 332.591 252.116 319.321 252.116 316.78C252.116 314.239 208.557 312.693 203.228 312.693C197.9 312.693 204.68 321.877 204.68 321.877Z"
            fill="white"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M252.116 316.78C252.116 316.78 248.239 318.816 246.787 326.469C245.336 334.122 243.404 338.209 246.308 340.244C249.212 342.28 261.801 343.811 263.732 341.775C265.663 339.739 270.992 329.53 271.965 322.903C272.937 316.275 252.116 316.78 252.116 316.78Z"
            fill="#15C39A"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M268.566 319.321C266.315 322.044 264.519 325.149 263.252 328.505C262.226 332.017 261.416 335.596 260.827 339.219C265.459 340.558 269.988 342.267 274.374 344.331C282.128 347.898 295.195 350.454 307.769 353.515C320.343 356.576 323.741 359.637 328.097 359.637C332.453 359.637 337.781 356.056 344.562 347.393C351.343 338.729 345.52 320.346 338.754 314.729C331.988 309.112 295.674 304.535 290.839 306.066C286.004 307.597 268.566 319.321 268.566 319.321Z"
            fill="#263238"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M177.76 337.26C176.804 332.681 174.867 328.393 172.097 324.724C171.647 325.168 171.212 325.627 170.791 326.132C165.404 332.591 168.772 337.183 177.76 337.26Z"
            fill="#15C39A"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M226.213 341.3C226.213 341.3 221.218 397.642 211.025 406.413"
            stroke="white"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M222.685 394.78C222.685 394.78 218.721 413.148 208.325 420.449"
            stroke="white"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M286.963 331.061C286.963 331.061 271.471 332.086 281.634 339.219C291.798 346.352 310.195 350.959 310.195 350.959"
            stroke="white"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M399.432 311.76L394.205 306.8L382.125 279.249C381.552 277.996 380.652 276.94 379.531 276.206C378.41 275.471 377.114 275.088 375.794 275.101H141.156C139.834 275.085 138.535 275.467 137.411 276.202C136.287 276.936 135.385 277.994 134.811 279.249L119.754 313.566L117.271 312.602V320.454L117.388 320.362C117.536 321.409 118.04 322.364 118.807 323.049C119.573 323.733 120.55 324.1 121.555 324.081H395.382C396.439 324.107 397.465 323.698 398.243 322.941C399.021 322.184 399.489 321.139 399.549 320.025L399.432 311.76Z"
            fill="#263238"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M395.382 316.902H121.555C120.877 316.931 120.203 316.777 119.597 316.456C118.991 316.135 118.471 315.657 118.088 315.066C117.705 314.476 117.471 313.792 117.407 313.08C117.343 312.368 117.451 311.65 117.722 310.994L134.812 272.071C135.383 270.813 136.284 269.753 137.409 269.018C138.533 268.283 139.834 267.903 141.157 267.923H375.795C377.115 267.906 378.413 268.287 379.535 269.022C380.656 269.757 381.555 270.815 382.126 272.071L399.215 310.994C399.486 311.65 399.595 312.368 399.531 313.08C399.467 313.792 399.232 314.476 398.849 315.066C398.466 315.657 397.947 316.135 397.34 316.456C396.734 316.777 396.06 316.931 395.382 316.902Z"
            fill="white"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
            <path
              d="M135.421 290.76C135.421 290.76 129.221 295.244 133.097 296.882C136.974 298.52 181.535 297.295 186.965 295.658C192.396 294.02 198.581 278.913 198.581 276.464C198.581 274.015 190.77 272.255 187.895 272.117C185.02 271.979 147.036 274.413 147.036 274.413L135.421 290.76Z"
              fill="#15C39A"
            />
          </g>
          <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
            <path
              d="M357.078 298.52C365.851 298.52 372.962 295.409 372.962 291.571C372.962 287.733 365.851 284.622 357.078 284.622C348.305 284.622 341.193 287.733 341.193 291.571C341.193 295.409 348.305 298.52 357.078 298.52Z"
              fill="#15C39A"
            />
          </g>
          <g style={{ mixBlendMode: "multiply" }} opacity="0.5">
            <path
              d="M255.949 307.107C287.408 307.107 312.91 302.077 312.91 295.872C312.91 289.667 287.408 284.637 255.949 284.637C224.49 284.637 198.988 289.667 198.988 295.872C198.988 302.077 224.49 307.107 255.949 307.107Z"
              fill="#15C39A"
            />
          </g>
          <path
            d="M345.463 264.234C345.463 264.234 344.418 259.841 340.947 261.311C337.477 262.78 337.826 272.484 338.508 277.979C339.191 283.474 343.895 280.903 343.895 280.903V277.612C343.895 277.612 342.167 279.066 341.296 277.061C340.425 275.056 340.599 264.969 341.47 265.152C342.341 265.336 344.069 268.443 344.069 268.443C344.069 268.443 344.592 268.091 345.463 264.234Z"
            fill="#15C39A"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M342.965 264.969V289.03C342.965 291.862 349.136 294.158 356.759 294.158C364.381 294.158 370.538 291.862 370.538 289.03V264.969H342.965Z"
            fill="#15C39A"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M370.538 264.969C370.538 267.418 364.367 269.392 356.759 269.392C349.15 269.392 342.965 267.418 342.965 264.969C342.965 262.52 349.136 260.545 356.759 260.545C364.381 260.545 370.538 262.535 370.538 264.969Z"
            fill="white"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M368.738 264.969C368.738 267.096 363.365 268.811 356.759 268.811C350.152 268.811 344.766 267.096 344.766 264.969C344.766 262.841 350.138 261.127 356.759 261.127C363.38 261.127 368.738 262.841 368.738 264.969Z"
            fill="white"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M347.511 267.418C353.546 269.275 359.959 269.275 365.994 267.418C359.959 265.561 353.546 265.561 347.511 267.418Z"
            fill="#263238"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M133.708 275.958L133.882 284.193L186.516 284.744V275.224L133.708 275.958Z"
            fill="white"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M186.516 284.744L194.168 267.356V262.229L186.168 276.877L186.516 284.744Z"
            fill="white"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M133.708 283.459L133.882 291.709L186.516 292.26V282.739L133.708 283.459Z"
            fill="white"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M186.516 292.26L194.168 274.857V269.729L186.168 284.377L186.516 292.26Z"
            fill="white"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M154.035 255.081C154.035 255.081 155.255 252.525 153.513 251.423C151.77 250.321 149.868 252.953 148.823 253.795C147.777 254.637 125.896 269.913 125.199 272.3C124.502 274.688 125.199 279.953 129.889 280.535C134.579 281.117 138.746 277.795 140.837 277.244C142.928 276.693 143.741 276.51 143.741 276.51L158.159 255.999L154.035 255.081Z"
            fill="white"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M129.89 280.55C134.579 281.101 138.747 277.811 140.837 277.26C142.928 276.709 143.741 276.525 143.741 276.525L144.264 275.775L130.717 272.867C129.981 272.135 129.034 271.684 128.026 271.584C127.019 271.484 126.009 271.742 125.156 272.316C124.503 274.673 125.2 279.984 129.89 280.55Z"
            fill="white"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M130.76 272.897H171.415C184.105 272.897 187.924 272.163 187.924 272.163L195.735 255.127C195.735 255.127 171.052 256.78 163.429 256.229C160.279 256.133 157.142 255.765 154.049 255.127L130.76 272.897Z"
            fill="white"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M130.76 272.897L131.457 276.188C131.457 276.188 164.46 276.372 173.143 276.372C181.825 276.372 188.432 276.739 188.432 276.739L187.909 272.147L130.76 272.897Z"
            fill="white"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M188.433 276.693L195.722 259.106V255.081L187.91 272.117L188.433 276.693Z"
            fill="white"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M305.693 297.265H206.161C205.94 297.267 205.722 297.223 205.517 297.135C205.313 297.048 205.127 296.918 204.971 296.755C204.814 296.591 204.69 296.396 204.605 296.182C204.52 295.967 204.477 295.737 204.477 295.505C204.477 295.272 204.52 295.042 204.605 294.827C204.69 294.613 204.814 294.418 204.971 294.254C205.127 294.091 205.313 293.961 205.517 293.874C205.722 293.786 205.94 293.742 206.161 293.744H305.693C305.913 293.742 306.131 293.786 306.335 293.874C306.538 293.962 306.723 294.091 306.878 294.255C307.034 294.419 307.157 294.614 307.24 294.829C307.323 295.043 307.365 295.273 307.363 295.505C307.365 295.736 307.323 295.966 307.24 296.181C307.157 296.395 307.034 296.59 306.878 296.754C306.723 296.918 306.538 297.047 306.335 297.135C306.131 297.223 305.913 297.267 305.693 297.265Z"
            fill="white"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M306.535 293.714H205.303C204.933 293.714 204.576 293.566 204.306 293.298C204.036 293.031 203.874 292.665 203.851 292.275L198.348 222.555C198.324 222.339 198.343 222.12 198.405 221.913C198.466 221.706 198.569 221.514 198.707 221.352C198.844 221.19 199.013 221.06 199.201 220.972C199.39 220.884 199.594 220.839 199.8 220.841H312.052C312.258 220.839 312.463 220.884 312.651 220.972C312.84 221.06 313.008 221.19 313.146 221.352C313.283 221.514 313.386 221.706 313.448 221.913C313.51 222.12 313.529 222.339 313.504 222.555L308.001 292.275C307.979 292.667 307.814 293.036 307.541 293.304C307.268 293.571 306.908 293.718 306.535 293.714Z"
            fill="white"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M265.474 258.479C265.474 262.642 261.191 266.01 255.92 266.01C250.649 266.01 246.352 262.642 246.352 258.479C246.352 254.316 250.635 250.964 255.92 250.964C261.205 250.964 265.474 254.331 265.474 258.479Z"
            fill="white"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M364.396 204.019C365.949 202.743 367.041 200.944 367.489 198.923C367.815 197.407 367.781 195.829 367.388 194.331"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M352.809 258.601C352.809 258.601 362.465 252.479 359.314 245.484C356.163 238.489 345.506 233.851 345.506 222.953C345.506 213.555 354.987 210.081 361.173 206.316"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M361.725 258.402C361.725 258.402 367.111 254.622 367.111 248.622C367.111 244.596 363.031 241.964 357.645 235.489"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M354.669 231.571C354.669 231.571 351.14 228.647 353.739 223.152"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M272.923 71.2387C274.651 70.8561 278.353 69.3561 280.851 63.2489C280.889 63.1565 280.91 63.057 280.912 62.9561C280.913 62.8552 280.896 62.7549 280.861 62.661C280.826 62.5671 280.774 62.4815 280.707 62.409C280.64 62.3365 280.561 62.2786 280.473 62.2387C280.296 62.161 280.096 62.159 279.917 62.2333C279.739 62.3075 279.594 62.4522 279.515 62.6367C277.221 68.2387 273.939 69.4785 272.574 69.754C275.017 66.638 276.365 62.7297 276.393 58.6877C276.393 48.0652 267.057 39.4631 255.543 39.4631C244.029 39.4631 234.692 48.0652 234.692 58.6877C234.7 62.5667 235.926 66.3339 238.177 69.402C235.985 69.2795 232.282 70.2591 227.592 76.0296C227.458 76.1752 227.383 76.3701 227.383 76.5729C227.383 76.7758 227.458 76.9706 227.592 77.1163C227.727 77.2342 227.896 77.2992 228.071 77.3C228.177 77.3001 228.281 77.2754 228.377 77.2278C228.472 77.1801 228.556 77.1107 228.623 77.0244C234.62 69.6622 238.584 70.902 239.382 71.2387C238.027 72.0248 236.756 72.964 235.593 74.0397C228.899 80.3918 224.819 92.4071 225.342 102.417C225.929 109.057 228.212 115.404 231.948 120.785C236.81 117.117 240.841 112.36 243.753 106.856C245.923 102.813 247.862 98.6373 249.561 94.351C249.561 98.8357 250.112 104.254 252.348 108.127C253.961 111.185 256.324 113.731 259.19 115.497C262.056 117.264 265.32 118.186 268.639 118.167C269.786 118.185 270.93 118.067 272.052 117.815C272.15 117.789 272.242 117.742 272.322 117.676C272.402 117.61 272.468 117.527 272.516 117.433C278.121 118.688 283.696 118.581 284.553 117.326C285.932 115.32 287.893 109.06 288.546 101.407C289.737 88.4275 281.983 77.1622 272.923 71.2387ZM253.699 107.193C250.882 102.341 250.969 94.4275 251.303 89.8816L251.492 89.3612C251.626 94.0367 252.756 98.6204 254.8 102.772C256.843 106.924 259.747 110.538 263.296 113.346C265.257 114.774 267.431 115.846 269.728 116.514C266.171 116.775 258.853 116.055 253.699 107.193Z"
            fill="#263238"
          />
          <path
            d="M253.945 83.101C253.945 83.101 252.754 92.3765 263.934 101.468C275.115 110.56 285.714 106.979 285.714 106.979"
            stroke="white"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M255.586 80.0398C255.586 80.0398 267.361 98.1164 283.623 93.4021"
            stroke="white"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M249.763 74.0242C247.906 73.0303 245.826 72.5931 243.75 72.7605C241.673 72.9278 239.68 73.6932 237.988 74.9732C231.73 79.3661 228.594 89.7436 228.594 89.7436"
            stroke="white"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M250.069 79.0601C250.069 79.0601 245.001 77.6366 241.865 80.9428C240.337 82.403 239.029 84.1012 237.988 85.9785"
            stroke="white"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <path
          d="M478.598 58.0001H393.277C390.363 58.0001 388 60.5145 388 63.6161V163.706C388 166.808 390.363 169.322 393.277 169.322H478.598C481.512 169.322 483.875 166.808 483.875 163.706V63.6161C483.875 60.5145 481.512 58.0001 478.598 58.0001Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.614583"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M393.277 58.0001H478.589C479.982 58.0001 481.317 58.5888 482.302 59.6368C483.287 60.6847 483.84 62.106 483.84 63.5881V69.4182H388V63.616C387.997 62.8775 388.131 62.1455 388.395 61.4625C388.659 60.7795 389.047 60.1589 389.538 59.6367C390.029 59.1145 390.612 58.7009 391.254 58.42C391.896 58.1391 392.583 57.9964 393.277 58.0001Z"
          fill="#263238"
          stroke="#263238"
          strokeWidth="0.614583"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M393.225 117.438H431.802"
          stroke="#263238"
          strokeWidth="0.614583"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M393.225 122.737H431.802"
          stroke="#263238"
          strokeWidth="0.614583"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M393.225 128.036H431.802"
          stroke="#263238"
          strokeWidth="0.614583"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M393.225 133.336H431.802"
          stroke="#263238"
          strokeWidth="0.614583"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M393.225 138.635H431.802"
          stroke="#263238"
          strokeWidth="0.614583"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M393.225 143.944H431.802"
          stroke="#263238"
          strokeWidth="0.614583"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M393.225 149.243H431.802"
          stroke="#263238"
          strokeWidth="0.614583"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M393.225 154.542H431.802"
          stroke="#263238"
          strokeWidth="0.614583"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M393.225 159.841H431.802"
          stroke="#263238"
          strokeWidth="0.614583"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M438.584 117.438H477.162"
          stroke="#263238"
          strokeWidth="0.614583"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M438.584 122.737H477.162"
          stroke="#263238"
          strokeWidth="0.614583"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M438.584 128.036H477.162"
          stroke="#263238"
          strokeWidth="0.614583"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M438.584 133.336H477.162"
          stroke="#263238"
          strokeWidth="0.614583"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M438.584 138.635H477.162"
          stroke="#263238"
          strokeWidth="0.614583"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M438.584 143.944H477.162"
          stroke="#263238"
          strokeWidth="0.614583"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M438.584 149.243H477.162"
          stroke="#263238"
          strokeWidth="0.614583"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M438.584 154.542H477.162"
          stroke="#263238"
          strokeWidth="0.614583"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M438.584 159.841H477.162"
          stroke="#263238"
          strokeWidth="0.614583"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M419.025 83.8819C419.215 83.2526 419.316 82.5964 419.322 81.9354C419.322 79.5232 417.021 79.3835 414.763 79.3835H414.194V78.8154H424.048V79.3835C421.484 79.3835 420.548 81.1251 419.751 83.7049L414.5 100.385C414.106 101.624 413.774 102.546 412.54 102.546H412.435L407.814 86.6107L403.517 100.422C403.114 101.661 402.782 102.583 401.548 102.583H401.451L394.73 79.3835H392.367V78.8154H401.014V79.3835H398.555L403.543 97.6563H403.744L407.508 85.521L405.757 79.4301H403.394V78.8154H412.05V79.3835H409.591L414.579 97.6563H414.772L419.025 83.8819Z"
          fill="#15C39A"
        />
        <path
          d="M477.434 73.5348H437.736V111.719H477.434V73.5348Z"
          fill="#15C39A"
          stroke="#263238"
          strokeWidth="1.22917"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M477.433 89.4978C476.102 89.5785 474.779 89.7747 473.477 90.0845C467.447 91.4815 465.872 100.692 459.054 100.692C453.016 100.692 452.499 92.0403 443.844 90.9227C441.738 90.5698 439.582 90.9763 437.718 92.0776V111.719H477.433V89.4978Z"
          fill="#263238"
          stroke="#263238"
          strokeWidth="0.614583"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M450.924 82.2705C450.926 83.2107 450.665 84.1304 450.175 84.9129C449.685 85.6954 448.988 86.3056 448.173 86.6663C447.357 87.0269 446.458 87.1218 445.592 86.9388C444.725 86.7558 443.929 86.3033 443.304 85.6384C442.68 84.9736 442.254 84.1264 442.082 83.2042C441.91 82.282 442 81.3262 442.339 80.4579C442.677 79.5896 443.251 78.8479 443.986 78.3266C444.722 77.8054 445.586 77.5282 446.469 77.53C447.651 77.53 448.784 78.0295 449.619 78.9185C450.454 79.8075 450.924 81.0133 450.924 82.2705Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.614583"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M479.245 61.3436H390.879V64.9665H479.245V61.3436Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.614583"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M473.732 191.535H435.478C433.974 191.535 432.532 192.171 431.468 193.302C430.405 194.434 429.807 195.969 429.807 197.57V219.344C429.807 220.945 430.405 222.48 431.468 223.612C432.532 224.743 433.974 225.379 435.478 225.379H436.651C435.461 228.173 433.15 232.457 429.501 235C429.501 235 437.264 234.423 445.35 225.379H473.715C475.219 225.379 476.661 224.743 477.725 223.612C478.788 222.48 479.386 220.945 479.386 219.344V197.57C479.386 195.972 478.791 194.44 477.731 193.309C476.671 192.178 475.233 191.54 473.732 191.535Z"
          fill="#263238"
          stroke="#263238"
          strokeWidth="0.614583"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M448.028 207.684C448.03 207.068 447.859 206.465 447.539 205.952C447.218 205.439 446.762 205.039 446.227 204.803C445.693 204.566 445.104 204.504 444.537 204.623C443.969 204.743 443.447 205.039 443.038 205.475C442.628 205.911 442.35 206.466 442.237 207.07C442.125 207.674 442.184 208.301 442.406 208.869C442.628 209.438 443.004 209.924 443.486 210.265C443.968 210.606 444.535 210.787 445.113 210.785C445.886 210.783 446.626 210.455 447.172 209.874C447.718 209.293 448.025 208.506 448.028 207.684Z"
          fill="#15C39A"
          stroke="#263238"
          strokeWidth="0.614583"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M457.943 207.684C457.943 207.071 457.772 206.471 457.452 205.961C457.132 205.451 456.676 205.053 456.144 204.819C455.611 204.584 455.025 204.523 454.46 204.642C453.895 204.762 453.375 205.057 452.968 205.491C452.56 205.925 452.283 206.477 452.17 207.079C452.058 207.681 452.116 208.304 452.336 208.871C452.557 209.438 452.93 209.922 453.409 210.263C453.889 210.603 454.452 210.785 455.029 210.785C455.801 210.783 456.541 210.455 457.087 209.874C457.633 209.293 457.941 208.506 457.943 207.684Z"
          fill="#15C39A"
          stroke="#263238"
          strokeWidth="0.614583"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M467.868 207.684C467.869 207.068 467.699 206.466 467.379 205.953C467.059 205.44 466.603 205.04 466.069 204.803C465.535 204.567 464.947 204.504 464.38 204.623C463.812 204.742 463.29 205.037 462.881 205.472C462.471 205.906 462.192 206.461 462.078 207.064C461.965 207.668 462.022 208.294 462.243 208.863C462.464 209.432 462.839 209.918 463.32 210.26C463.801 210.603 464.366 210.785 464.945 210.785C465.718 210.783 466.458 210.456 467.006 209.875C467.553 209.294 467.863 208.507 467.868 207.684Z"
          fill="#15C39A"
          stroke="#263238"
          strokeWidth="0.614583"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_246_492"
          x="57.8125"
          y="455.224"
          width="400.642"
          height="90.7758"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="4" dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_246_492"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_246_492"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_246_492"
          x="116.771"
          y="39.4631"
          width="291.277"
          height="485.21"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="4" dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_246_492"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_246_492"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_246_492">
          <rect width="527" height="544" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StudyingGirl;
